export const hsCodes = [
  {
    label: 'Vinyl Record',
    value: 8523809000,
    origin: 'GBR',
    qty: 1,
  },
  {
    label: 'CD',
    value: 8523492000,
    origin: 'GBR',
    qty: 1,
  },
  {
    label: 'Cassette Tape',
    value: 8523291900,
    origin: 'GBR',
    qty: 1,
  },
  {
    label: 'Poster',
    value: 4901990000,
    origin: 'GBR',
    qty: 1,
  },
  {
    label: 'T-Shirt',
    value: 6109100010,
    origin: 'GBR',
    qty: 1,
  },
  {
    label: 'Sweatshirt',
    value: 6109100010,
    origin: 'GBR',
    qty: 1,
  },
];

export const marketplaces = [
  {
    value: 'amazon',
    label: 'Amazon',
    ioss: 'IM4420001201',
  },
  {
    value: 'ebay',
    label: 'eBay',
    ioss: 'IM2760000742',
  },
  {
    value: 'bandcamp',
    label: 'Bandcamp',
    ioss: 'Coming Soon',
  },
  {
    value: 'discogs',
    label: 'Discogs',
    ioss: 'Coming Soon',
  },
];
