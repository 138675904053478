import axios from 'axios';

export const AUTHENTICATED = 'authenticated_user';
export const UNAUTHENTICATED = 'unauthenticated_user';
export const AUTHENTICATION_ERROR = 'authentication_error';
export const SET_CURRENT_USER = 'set_current_user';

const URL = 'http://136.144.230.112:8000';

export function signInAction({ email, password }, history) {
  return async (dispatch) => {
    try {
      const postData = {
        email,
        password,
      };
      const res = await axios.post(`${URL}/admin/signin`, postData);
      dispatch({ type: AUTHENTICATED });
      dispatch({
        type: SET_CURRENT_USER,
        user: res.data.user,
      });
      localStorage.setItem('mm_admin', res.data.token);
      history.push('/dashboard');
    } catch (error) {
      dispatch({
        type: AUTHENTICATION_ERROR,
        payload: 'Invalid email or password',
      });
    }
  };
}

export function getUserAction(user, history) {
  return async (dispatch) => {
    try {
      const res = await axios.get(`${URL}/user`, { headers: { Authorization: user } });
      console.log(res);
      dispatch({ type: AUTHENTICATED });
      dispatch({
        type: SET_CURRENT_USER,
        user: res.data.user,
      });
      history.push('/dashboard');
    } catch (error) {
      console.log(error);
    }
  };
}

export function signOutAction() {
  localStorage.clear();
  return {
    type: UNAUTHENTICATED,
  };
}
