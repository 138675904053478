import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';
import { signOutAction } from '../../../redux/actions/authActions';

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

class TopbarProfile extends PureComponent {
  static propTypes = {
    signOutAction: PropTypes.func.isRequired,
    user: PropTypes.shape.isRequired,
  };

  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };

  signOut() {
    this.props.signOutAction();
  }

  render() {
    return (
      <div className="topbar__profile">
        <button className="topbar__avatar" onClick={this.toggle}>
          <img className="topbar__avatar-img" src={Ava} alt="avatar" />
          <p className="topbar__avatar-name">{this.props.user.name}</p>
          <DownIcon className="topbar__icon" />
        </button>
        {this.state.collapse && <button className="topbar__back" onClick={this.toggle} />}
        <Collapse isOpen={this.state.collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            <TopbarMenuLink title="Account" icon="list" path="/pages/one" />
            <div className="topbar__menu-divider" />
            <TopbarMenuLink title="Log Out" icon="exit" path="/" onClick={() => this.signOut()} />
          </div>
        </Collapse>
      </div>
    );
  }
}


// const mapStateToProps = (state) => {
//   console.log(state.user);
//   return {
//     user: state.user,
//   };
// };

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

// const mapStateToProps = state => ({
//   user: state.user,
// });

export default connect(mapStateToProps, { signOutAction })(TopbarProfile);
