/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable camelcase */


import React, { Component } from 'react';
import { Card, CardDeck, CardBody, Col, Button, ButtonToolbar, ButtonGroup } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import axios from 'axios';
import { connect } from 'react-redux';
import countryList from '../../../../helpers/shipping/Countries';
import DhlLogo from '../../../../resources/dhl_logo.png';
import RmLogo from '../../../../resources/rm_logo.png'; // with import


const currencyFormat = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' });
const URL = 'http://136.144.230.112:8000';

// const currencyFormat = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' })

class ShipmentsTable extends Component {
  constructor() {
    super();
    this.state = {
      shipmentBatches: [],
      rmShipments: [],
      dhlShipments: [],
      dhlManifestCheck: false,
      rmManifestCheck: false,
      dhlBagCount: undefined,
      // selectedShipment: {},
    };
    this.getShipments();
  }

  async getShipments() {
    const response = await axios.post(`${URL}/admin/shipping/shipments`, {
      page: 1,
      pageSize: 1000,
      sorted: null,
      filtered: null,
    }, { headers: { Authorization: localStorage.getItem('mm_admin') } });

    const grouped = [];

    response.data.items.map((shipment) => {
      if (grouped.some(group => group.batch_id === shipment.batch_id)) {
        const found = grouped.find(batch => batch.batch_id === shipment.batch_id);
        found.shipments.push(shipment);
      } else {
        grouped.push({ batch_id: shipment.batch_id, shipments: [shipment] });
      }
    });

    grouped.forEach(batch => batch.shipments.sort((a, b) => (a.batch_index > b.batch_index ? 1 : -1)));

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    yesterday.setHours(16, 30, 0);

    this.setState({
      shipmentBatches: grouped,
      rmShipments: response.data.items.filter(item => item.carrier === 'RMG' && new Date(item.dispatch_date) > yesterday),
      dhlShipments: response.data.items.filter(item => item.carrier === 'DHL' && new Date(item.dispatch_date) > yesterday),
    });
  }

  async manifestRmShipments() {
    axios.post(`${URL}/admin/shipping/rm/manifestorder`, {
    }, {
      headers: {
        Authorization: localStorage.getItem('mm_admin'),
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
      },
      responseType: 'arraybuffer',
    })
      .then((res) => {
      // // Update react-table
        const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'manifestDhl.pdf'); // or any other extension
        document.body.appendChild(link);
        link.click();
      });
  }

  async manifestDhlShipments(bagCount) {
    if (bagCount > 0) {
      axios.post(`${URL}/admin/shipping/dhl/manifestorder`, {
        bagCount,
      }, {
        headers: {
          Authorization: localStorage.getItem('mm_admin'),
          'Content-Type': 'application/json',
          Accept: 'application/pdf',
        },
        responseType: 'arraybuffer',
      })
        .then((res) => {
        // Update react-table
          const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'manifestDhl.pdf'); // or any other extension
          document.body.appendChild(link);
          link.click();
        });
    }
  }

  async cancelShipments(shipments) {
    await axios.post(`${URL}/admin/shipping/shipments/cancel`, {
      shipments,
    }, {
      headers: {
        Authorization: localStorage.getItem('mm_admin'),
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((res) => {
        console.log(res.data);
        const shipmentsToRemove = [];
        shipmentsToRemove.push(...res.data.resData.rmRes.ShipmentIds);
        res.data.resData.dhlRes.map((item) => {
          if (item.cancelData === 200) {
            shipmentsToRemove.push(item.carrier_ref);
          }
        });

        const currentBatches = this.state.shipmentBatches;

        shipmentsToRemove.map((canceledId) => {
          currentBatches.map((batch, batchIndex) => {
            const index = batch.shipments.findIndex(shipment => shipment.carrier_ref === canceledId);
            if (index !== -1) {
              batch.shipments.splice(index, 1);
              if (batch.shipments.length === 0) {
                currentBatches.splice(batchIndex, 1);
              }
            }
          });
        });

        this.setState({
          shipmentBatches: currentBatches,
        });
      });
  }

  async printShipmentLabels(shipments) {
    await axios.post(`${URL}/admin/shipping/shipments/labels`, {
      shipments,
    }, {
      headers: {
        Authorization: localStorage.getItem('mm_admin'),
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
      },
      responseType: 'arraybuffer',
    })
      .then((res) => {
        // console.log(res.data);
        const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          'Labels-test',
        );
        document.body.appendChild(link);
        link.click();
      });
  }

  render() {
    const batchesColumns = [
      {
        Header: 'Batch Id',
        id: 'batch_id',
        accessor: 'batch_id',
      }, {
        Header: '# of Shipments',
        id: 'shipments_count',
        Cell: row => (
          <div>
            <span>{row.original.shipments.length}</span>
          </div>
        ),
      }, {
        Header: 'Date',
        id: 'batch_date',
        Cell: row => (
          <div>
            <span>{new Date(Number(row.original.batch_id)).toLocaleString('en-GB')}</span>
          </div>
        ),
      }, {
        Header: 'Actions',
        id: 'actions',
        Cell: row => (
          <div>
            <Button color="primary" size="sm" onClick={() => { this.printShipmentLabels(row.original.shipments); }}>Print Batch</Button>
            <Button color="danger" size="sm" onClick={() => { this.cancelShipments(row.original.shipments); }}>Cancel Batch</Button>
          </div>
        ),
      },
    ];

    const shipmentsColumns = [
      {
        Header: 'Reference',
        id: 'customer_ref',
        accessor: 'customer_ref',
      }, {
        Header: 'Name',
        id: 'customer_name',
        accessor: 'customer_name',
      }, {
        Header: 'Country',
        id: 'country',
        accessor: 'country',
      }, {
        Header: 'Weight',
        id: 'weight',
        accessor: 'weight',
      }, {
        Header: 'Service',
        id: 'service',
        accessor: 'service',
      }, {
        Header: 'Tracking',
        id: 'tracking_no',
        Cell: (row) => {
          if (row.original.service_code === 'CRL24' || row.original.service_code === 'CRL48') {
            return row.original.carrier_ref;
          }
          return row.original.tracking_no;
        },
      },
    ];

    const actionsColumns = [
      {
        Header: '',
        id: 'cancel_shipment',
        Cell: row => <Button color="primary" size="sm" onClick={() => {}}>Print Batch</Button>,
      }, {
        Header: '',
        id: 'print_label',
        Cell: row => <Button color="primary" size="sm" onClick={() => {}}>Print Batch</Button>,
      },
    ];

    return (
      <Col style={{ height: '100%' }}>
        <Card style={{ width: '100%' }}>
          <CardBody>
            <div>
              <span>TEST</span>
            </div>
          </CardBody>
        </Card>
        <CardDeck>
          <Card>
            <CardBody>
              <div>
                <img src={DhlLogo} width="100" height="90" alt="DHL Logo" />
                <br />
                <br />
                <center><h1>{this.state.dhlShipments.length}</h1></center>
                <center><h5>Current Open Shipments</h5></center>
                <br />
                <center>
                  <td>
                    <input
                      type="checkbox"
                      onChange={e => (this.setState({ dhlManifestCheck: !this.state.dhlManifestCheck }))}
                    />
                    {this.state.dhlManifestCheck ? (
                      (
                        <center>
                          <h5>Number of bags:</h5>
                          <input
                            type="number"
                            onChange={e => (this.setState({ dhlBagCount: e.target.value }))}
                          />
                        </center>
                    )) : null }
                    <br />
                  </td>
                  <Button
                    color="primary"
                    disabled={!this.state.dhlManifestCheck || this.state.dhlBagCount === undefined}
                    className="rounded"
                    onClick={() => { this.manifestDhlShipments(this.state.dhlBagCount); }}
                  >Create Manifest
                  </Button>
                </center>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <div>
                <img src={RmLogo} width="100" height="90" alt="RM Logo" />
                <br />
                <br />
                <center><h1>{this.state.rmShipments.length}</h1></center>
                <center><h5>Current Open Shipments</h5></center>
                <br />
                <center>
                  <td>
                    <input
                      type="checkbox"
                      onChange={e => (this.setState({ rmManifestCheck: !this.state.rmManifestCheck }))}
                    />
                  </td>
                  <Button
                    color="primary"
                    disabled={!this.state.rmManifestCheck}
                    className="rounded"
                    onClick={() => { this.manifestRmShipments(); }}
                  >Create Manifest
                  </Button>
                </center>
              </div>
            </CardBody>
          </Card>
        </CardDeck>
        <Card>
          <CardBody style={{ height: '800px' }}>
            <ReactTable
              data={this.state.shipmentBatches}
              columns={batchesColumns}
              showPagination={false}
              className="-striped -highlight -responsive"
              filterable
              collapseOnDataChange={false}
              style={{
                height: '100%',
              }}
              defaultPageSize={150}
              SubComponent={row => (
                <div style={{ padding: '20px' }}>
                  <ReactTable
                    data={row.original.shipments}
                    columns={shipmentsColumns}
                    defaultPageSize={row.original.shipments.length}
                    showPagination={false}
                    // getTrProps={(state, rowInfo) => {
                    //   if (rowInfo !== undefined) {
                    //     return {
                    //     onClick: (e) => {
                    //     this.setState({
                    //     selectedShipment: rowInfo.original,
                    //     });
                    //   },
                    //   style: {
                    //       background: rowInfo.original === this.state.selectedShipment ? '#00afec' : 'white',
                    //       color: rowInfo.original === this.state.selectedShipment ? 'white' : 'black',
                    //     },
                    //   };
                    // }
                    // return {};
                    // }}
                    SubComponent={row2 => (
                      <div style={{ padding: '20px' }}>
                        <ButtonToolbar>
                          <Button color="primary" size="sm" className="rounded" onClick={() => { this.printShipmentLabels([row2.original]); }}>Print Label</Button>
                          <Button color="danger" size="sm" className="rounded" onClick={() => { this.cancelShipments([row2.original]); }}>Cancel Shipment</Button>
                        </ButtonToolbar>
                      </div>
                    )}
                  />
                </div>
              )}
            />
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
});

// export default connect()(SidebarContent);
export default connect(mapStateToProps)(ShipmentsTable);
