import React from 'react';
import axios from 'axios';
import { Container, Col, Row, Button } from 'reactstrap';
import DHLOrdersTable from './components/DHLOrdersTable';


const URL = 'http://136.144.230.112:8000'; // http://-localhost:8000

function manifestOrder() {
  axios.post(`${URL}/admin/shipping/dhl/manifestorder`, {
  }, {
    headers: {
      Authorization: localStorage.getItem('mm_admin'),
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
    responseType: 'arraybuffer',
  })
    .then((res) => {
    // Update react-table
      const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'manifestDhl.pdf'); // or any other extension
      document.body.appendChild(link);
      link.click();
    });
  // return returnAddress;
}

const DHL = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h5 className="page-title">Shipping / DHL Shipments</h5>
        <Button
          color="primary"
          size="sm"
          onClick={() => manifestOrder()}
        >Manifest Order
        </Button>
      </Col>
    </Row>
    <Row>
      <DHLOrdersTable />
    </Row>
  </Container>
);

export default DHL;
