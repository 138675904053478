import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import ShipmentsTable from './components/ShipmentsTable';

const ManageShipments = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h5 className="page-title">Shipping / Manage Shipments</h5>
      </Col>
    </Row>
    <Row>
      <ShipmentsTable />
    </Row>
  </Container>
);

export default ManageShipments;
