import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';

import LogIn from '../LogIn/index';
import ExamplePageOne from '../Example/index';
import ExamplePageTwo from '../ExampleTwo/index';
import OpenOrders from '../Fulfillment/OpenOrders/index';
import AllOrders from '../Fulfillment/AllOrders/index';
import NewItems from '../Inventory/NewItems/index';
import AmazonOrders from '../Orders/Amazon/index';
import EbayOrders from '../Orders/Ebay/index';
import DiscogsOrders from '../Orders/Discogs/index';
import Purchases from '../Orders/Purchases/index';
import PurchaseManagement from '../Orders/PurchaseManagement/index';
import AboveBoardBandcampOrders from '../AboveBoard/Orders/BandCamp/index';
import AboveBoardBandcampOrdersDhl from '../AboveBoard/Orders/BandCampDhl/index';
import MerchItems from '../AboveBoard/Merch/index';
import Returns from '../AboveBoard/Orders/Returns/index';
import ShippingDhl from '../Shipping/DHL/index';
import Shipments from '../Shipping/Shipments/index';
import ManageShipments from '../Shipping/ManageShipments/index';
import ShippingToolsEbay from '../Tools/Shipping/Ebay/index';
import ShippingToolsAmazon from '../Tools/Shipping/Amazon/index';
import ShippingToolsFulfilment from '../Tools/Shipping/Fulfilment/index';
import ShippingToolsAboveBoard from '../Tools/Shipping/AboveBoard/index';
import ToolsUpdateOrders from '../Tools/Updates/index';


const Pages = () => (
  <Switch>
    <Route path="/pages/one" component={ExamplePageOne} />
    <Route path="/pages/two" component={ExamplePageTwo} />
  </Switch>
);

const Fulfillment = () => (
  <Switch>
    <Route path="/fulfillment/orders" component={OpenOrders} />
    <Route path="/fulfillment/allorders" component={AllOrders} />
  </Switch>
);

const Inventory = () => (
  <Switch>
    <Route path="/inventory/new_items" component={NewItems} />
  </Switch>
);

const Orders = () => (
  <Switch>
    <Route path="/orders/amazon" component={AmazonOrders} />
    <Route path="/orders/ebay" component={EbayOrders} />
    <Route path="/orders/discogs" component={DiscogsOrders} />
    <Route path="/orders/purchases/new" component={Purchases} />
    <Route path="/orders/purchases" component={PurchaseManagement} />

  </Switch>
);

const AboveBoard = () => (
  <Switch>
    <Route path="/aboveboard/orders/bandcamp" component={AboveBoardBandcampOrders} />
    <Route path="/aboveboard/orders/bandcampdhl" component={AboveBoardBandcampOrdersDhl} />
    <Route path="/aboveboard/orders/returns" component={Returns} />
    <Route path="/aboveboard/merch" component={MerchItems} />
  </Switch>
);

const Shipping = () => (
  <Switch>
    <Route path="/shipping/dhl" component={ShippingDhl} />
    <Route path="/shipping/shipments" component={Shipments} />
    <Route path="/shipping/manageshipments" component={ManageShipments} />
  </Switch>
);

const Tools = () => (
  <Switch>
    <Route path="/tools/orders/update" component={ToolsUpdateOrders} />
    <Route path="/tools/shipping/ebay" component={ShippingToolsEbay} />
    <Route path="/tools/shipping/amazon" component={ShippingToolsAmazon} />
    <Route path="/tools/shipping/fulfilment" component={ShippingToolsFulfilment} />
    <Route path="/tools/shipping/aboveboard" component={ShippingToolsAboveBoard} />
  </Switch>
);

const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/pages" component={Pages} />
      <Route path="/fulfillment" component={Fulfillment} />
      <Route path="/inventory" component={Inventory} />
      <Route path="/orders" component={Orders} />
      <Route path="/aboveboard" component={AboveBoard} />
      <Route path="/shipping" component={Shipping} />
      <Route path="/tools" component={Tools} />
    </div>
  </div>
);

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/" component={LogIn} />
        <Route exact path="/log_in" component={LogIn} />
        <Route path="/" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
