import React, { Component } from 'react';
import { Col, Row, Card, CardBody } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { connect } from 'react-redux';

const getColumnWidth = (rows, accessor, headerText) => {
  if (rows !== undefined) {
    const maxWidth = 400;
    let magicSpacing = 10;
    switch (accessor) {
      case 'total':
        magicSpacing += 3;
        break;
      case 'seller_id':
        magicSpacing += 10;
        break;
      case 'status':
        magicSpacing += 10;
        break;
      default:
    }
    const cellLength = Math.max(
      ...rows.map(row => (`${row[accessor]}` || '').length),
      headerText.length,
    );
    return Math.min(maxWidth, cellLength * magicSpacing);
  }
  return null;
};

class DiscogsOrdersTable extends Component {
  constructor() {
    super();
    this.state = {
      pages: 2,
      loading: false,
      selected: undefined,
      data: [{
        amazon_order_id: '402-2515884-8696361',
        order_date: '2019-07-12T17:26:32.083',
        buyer_name: 'Martino fadda',
        shipping_address1: 'Via c loi 17',
        shipping_address2: 'Via Cesare loi 17',
        shipping_address3: null,
        shipping_address_city: 'Guspini',
        shipping_address_county: null,
        shipping_address_region: 'SU',
        shipping_address_district: null,
        shipping_address_postcode: '09036',
        shipping_address_countrycode: 'IT',
        shipping_service_level: 'Std IT Dom_3',
        shipping_service_cat: 'Standard',
        order_channel: 'Amazon.it',
        buyer_email: 'bdg4kpgxykbvln7@marketplace.amazon.it',
        order_total: 36.28,
        latest_ship_date: '2019-07-16T00:00:00',
        earliest_del_date: '2019-07-19T00:00:00',
        latest_del_date: '2019-07-24T00:00:00',
        is_premium: false,
        status: 'Unshipped',
        items: [{
          amazon_order_id: '402-2515884-8696361',
          sku: '253590985d',
          sku_unique: '402-2515884-8696361%253590985d',
          asin: 'B01AQT7UEI',
          item_price: 16.99,
          shipping_price: 2.9,
          qty_ordered: 1,
          qty_shipped: 0,
          title: 'Supernature [Vinile] Cerrone',
          cond_id: 'Used',
          discogs_purchase_id: '528743-11334',
          discogs_purchase_status: 'New Order',
          discogs_purchase_seller: 'spellbinderz',
          order_item_id: '45464426387659',
          listing_id: 253590985,
          discogs_price: 1.95,
          release_id: 581059,
          inventory: {
            item: {
              listing_id: 253590985,
              discogs_price: 1.95,
              release_id: 581059,
            },
          },
        }, {
          amazon_order_id: '402-2515884-8696361',
          sku: '345797204d',
          sku_unique: '402-2515884-8696361%345797204d',
          asin: 'B000PHI7V2',
          item_price: 13.49,
          shipping_price: 2.9,
          qty_ordered: 1,
          qty_shipped: 0,
          title: 'KNOCK ON WOOD 12" SINGLE UK - [Vinile] Amii Stewart',
          cond_id: 'Used',
          discogs_purchase_id: '528743-11334',
          discogs_purchase_status: 'New Order',
          discogs_purchase_seller: 'null',
          order_item_id: '10983654480723',
          listing_id: 345797204,
          discogs_price: 1.0,
          release_id: 4643018,
          inventory: {
            item: {
              listing_id: 345797204,
              discogs_price: 1.0,
              release_id: 4643018,
            },
          },
        }],
      }],
    };
  }

  render() {
    const ordersColumns = [
      {
        Header: 'MM Status',
        width: getColumnWidth(this.state.data, 'MM Status', 'MM Status'),
        getProps: (state, row) => {
          if (row) {
            return {
              style: {
                background: row.original.items.find(item => item.discogs_purchase_seller === null) ?
                  'red' : 'green',
              },
            };
          }
          return {
            style: { className: '-striped -highlight' },
          };
        },
      },
      // {
      //   Header: 'Order Id',
      //   id: 'amazon_order_id',
      //   width: getColumnWidth(this.state.data, 'amazon_order_id', 'Order Id'),
      //   accessor: 'amazon_order_id',
      // },
      {
        Header: 'Customer',
        id: 'buyer_name',
        accessor: 'buyer_name',
      }, {
        Header: 'Status',
        id: 'status',
        width: getColumnWidth(this.state.data, 'status', 'Status'),
        accessor: 'status',
      }, {
        Header: 'Summary',
        id: 'summary',
        sortable: false,
        // accessor: d => {
        //   if (d.items.length > 1) {
        //     return d.items.length+' Items';
        //   } else {
        //     return d.items.length+' Item';
        //   }
        // }
        Cell: row => (
          <div>
            <span>
              <b>{(row.original.items.length > 1) ? `${row.original.items.length} Items:`
              : `${row.original.items.length} Item:`}
              </b>
            </span>
            {row.original.items.map(i =>
              <tr>{i ? i.title : '' }</tr>)}
          </div>
        ),
      },
    ];

    return (
      <Col md={12}>
        <Card>
          <CardBody>
            <ReactTable
              style={{
                height: '400px',
              }}
              data={this.state.data}
              columns={ordersColumns}
              pages={this.state.pages} // should default to -1 (which means we don't know how many pages we have)
              loading={this.state.loading}
              manual
              filterable
              multiSort={false}
              defaultPageSize={50}
              className="-striped -highlight -responsive"
              getTrProps={(state, rowInfo) => {
        if (rowInfo !== undefined) {
          return {
            onClick: () => {
              // console.log(rowInfo.original);
              this.setState({
                selected: rowInfo.original,
              });
            },
            style: {
              background: rowInfo.original === this.state.selected ? '#00afec' : 'white',
              color: rowInfo.original === this.state.selected ? 'white' : 'black',
            },
          };
        }
          return {};
      }}
            />
          </CardBody>
        </Card>
        <Row>
          <Col md={4}>
            <Card>
              <CardBody />
            </Card>
          </Col>
          <Col md={4}>
            <Card>
              <CardBody />
            </Card>
          </Col>
          <Col md={4}>
            <Card>
              <CardBody />
            </Card>
          </Col>
        </Row>
      </Col>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
});

// export default connect()(SidebarContent);
export default connect(mapStateToProps)(DiscogsOrdersTable);
