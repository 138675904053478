import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import ShippingToolsFulfillmentPage from './components/ShippingToolsFulfillmentPage';


const ShippingToolsFulfilment = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h5 className="page-title">Tools / Shipping / Fulfilment</h5>
      </Col>
    </Row>
    <Row>
      <ShippingToolsFulfillmentPage />
    </Row>
  </Container>
);

export default ShippingToolsFulfilment;
