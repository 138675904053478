/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */


import React, { Component } from 'react';
import { Card, CardDeck, CardBody, Col, Button, ButtonToolbar, ButtonGroup } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import axios from 'axios';
import { connect } from 'react-redux';
import countryList from '../../../../helpers/shipping/Countries';

const currencyFormat = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' });
const URL = 'http://136.144.230.112:8000';

// const currencyFormat = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' })

class DHLOrdersTable extends Component {
  constructor() {
    super();
    this.state = {
      orders: [],
      selectedOrder: {},
      selectedOrderItems: [],
      selectedOrderStatus: 'open',
    };
    this.getDhlOrders();
  }

  async getDhlOrders() {
    const response = await axios.post(`${URL}/admin/shipping/dhl/orders`, {
      page: 1,
      pageSize: 1000,
      sorted: null,
      filtered: null,
    }, { headers: { Authorization: localStorage.getItem('mm_admin') } });

    console.log(response);
    this.setState({
      orders: response.data.items,
    });
  }

  async getItemsForDhlOrder(orderId) {
    const response = await axios.post(`${URL}/admin/shipping/dhl/orders/items`, {
      orderId,
    }, { headers: { Authorization: localStorage.getItem('mm_admin') } });
    this.setState({
      selectedOrderItems: response.data.items,
    });
  }

  render() {
    const ordersColumns = [
      {
        Header: 'DHL Id',
        id: 'order_id',
        accessor: 'order_id',
      }, {
        Header: 'Status',
        id: 'order_status',
        accessor: 'order_status',
      }, {
        Header: 'Collection Date',
        id: 'pickup_date',
        filterable: false,
        Cell: row => (
          <div>
            <span>{new Date(row.original.pickup_date).toLocaleDateString()}</span>
          </div>
        ),
      }, {
        Header: 'Manifest Date',
        id: 'manifest_date',
        filterable: false,
        Cell: row => (
          <div>
            <span>{new Date(row.original.manifest_date).toLocaleDateString()}</span>
          </div>
        ),
      },
    ];

    const orderItemsColumns = [
      {
        Header: 'Reference',
        id: 'custRef',
        accessor: 'custRef',
      }, {
        Header: 'Name',
        id: 'recipient',
        accessor: 'recipient',
      }, {
        Header: 'Country',
        id: 'destinationCountry',
        accessor: 'destinationCountry',
      },
    ];

    return (
      <Col>
        <Card>
          <CardBody >
            <ButtonToolbar>
              <ButtonGroup className="btn-group--justified" dir="ltr">
                <Button outline={this.state.selectedOrderStatus !== 'open'} color="primary" onClick={() => this.setState({ selectedOrderStatus: 'open' })}>Open Orders</Button>
                <Button outline={this.state.selectedOrderStatus !== 'manifested'} color="primary" onClick={() => this.setState({ selectedOrderStatus: 'manifested' })}>Manifested Orders</Button>
              </ButtonGroup>
            </ButtonToolbar>
            <ReactTable
              data={this.state.orders}
              columns={ordersColumns}
              showPagination={false}
              className="-striped -highlight -responsive"
              filterable
              style={{
                height: '400px',
              }}
              defaultPageSize={20}
              getTrProps={(state, rowInfo) => {
                if (rowInfo !== undefined) {
                  return {
                  onClick: (e) => {
                  this.setState({
                  selectedOrder: rowInfo.original,
                  });
                this.getItemsForDhlOrder(rowInfo.original.order_id);
                },
                style: {
                    background: rowInfo.original === this.state.selectedOrder ? '#00afec' : 'white',
                    color: rowInfo.original === this.state.selectedOrder ? 'white' : 'black',
                  },
                };
              }
              return {};
              }}
            />
            <ReactTable
              data={this.state.selectedOrderItems}
              columns={orderItemsColumns}
              showPagination={false}
              className="-striped -highlight -responsive"
              filterable
              defaultPageSize={200}
              getTrProps={(state, rowInfo) => {
                if (rowInfo !== undefined) {
                  return {
                  onClick: (e) => {
                  this.setState({
                  selectedOrder: rowInfo.original,
                });
                console.log(rowInfo.original);
                this.getItemsForDhlOrder(rowInfo.original.order_id);
                    },
                    style: {
                    background: rowInfo.original === this.state.selectedOrder ? '#00afec' : 'white',
                    color: rowInfo.original === this.state.selectedOrder ? 'white' : 'black',
                  },
                };
              }
              return {};
              }}
              style={{
                height: '400px',
              }}
            />
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
});

// export default connect()(SidebarContent);
export default connect(mapStateToProps)(DHLOrdersTable);
