/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-nested-ternary */


import React, { Component } from 'react';
import { PDFDownloadLink, Document, Page, Text, StyleSheet, View, Link } from '@react-pdf/renderer';
import { Card, CardDeck, CardBody, Col, Button } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import axios from 'axios';
import { connect } from 'react-redux';
import { countryList } from '../../../../helpers/shipping/Countries';
import { domesticServices, internationalServices } from '../../../../helpers/shipping/Services';
import { getServicePrice, getMMServicePrice } from '../../../../helpers/shipping/Pricer';

const currencyFormat = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' });
const URL = 'http://136.144.230.112:8000'; // http://136.144.230.112:8000


let MyDoc;

const isEquals = require('lodash.isequal');

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
  },
  section: {
    margin: 10,
    padding: 0,
    borderBottomStyle: 'solid',
    borderBottomColor: 'black',
    borderBottomWidth: 1,
  },
  header: {
    fontSize: 16,
  },
  text: {
    fontSize: 12,
  },
  textHighlighted: {
    fontSize: 10,
    backgroundColor: 'lightgrey',
    margin: 10,
  },
});

const getColumnWidth = (rows, accessor, headerText) => {
  if (rows !== undefined) {
    const maxWidth = 400;
    let magicSpacing = 10;
    switch (accessor) {
      case 'total':
        magicSpacing += 3;
        break;
      case 'seller_id':
        magicSpacing += 10;
        break;
      case 'status':
        magicSpacing += 10;
        break;
      case 'sales_channel':
        magicSpacing += 5;
        break;
      default:
    }
    const cellLength = Math.max(
      ...rows.map(row => (`${row[accessor]}` || '').length),
      headerText.length,
    );
    return Math.min(maxWidth, cellLength * magicSpacing);
  }
  return null;
};

// const currencyFormat = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' })

class AllOrdersTable extends Component {
  constructor() {
    super();
    this.state = {
      pages: -1,
      loading: false,
      data: [],
      selected: undefined,
      selectedOriginal: undefined,
      selectedActWeight: null,
      selectedActualPrice: null,
      selectedMMPrice: null,
      sellers: [],
      shipmentBatch: [],
      selectedSize: null,
      isEditing: false,
    };
    this.handleWeightChange = this.handleWeightChange.bind(this);
    this.addToShipmentBatch = this.addToShipmentBatch.bind(this);
    this.removeFromShipmentBatch = this.removeFromShipmentBatch.bind(this);
    this.handleSizeChange = this.handleSizeChange.bind(this);
    this.handleItemChange = this.handleItemChange.bind(this);
    // this.submitShipmentBatch = this.submitShipmentBatch.bind(this);
  }

  async componentWillMount() {
    const response = await axios.get(
      `${URL}/admin/fulfillment/sellers`,
      { headers: { Authorization: localStorage.getItem('mm_admin') } },
    );
    this.setState({
      sellers: response.data.sellers,
    });
  }

  getCountryLabel(countryCode) {
    const countryObj = countryList.find(obj => obj.value === countryCode);
    if (countryObj !== undefined) {
      return countryObj.label;
    }
    return null;
  }

  getServiceLabel(serviceCode, countryCode) {
    let serviceObj;
    if (this.getCountryLabel(countryCode) === 'United Kingdom') {
      serviceObj = domesticServices.find(obj => obj.value === serviceCode);
    } else {
      serviceObj = internationalServices.find(obj => obj.value === serviceCode);
    }
    if (serviceObj !== undefined) {
      return serviceObj.label;
    }
    return null;
  }

  getSellerName(id) {
    const retSeller = this.state.sellers.find(seller => seller.id === id).name;
    if (retSeller) {
      return retSeller.name;
    }
    return null;
  }

  getConditionString(item) {
    let media = item.media_cond;
    let sleeve = item.sleeve_cond;

    switch (media) {
      case 'Near Mint (NM or M-)':
        media = 'NM';
        break;
      case 'Mint (M)':
        media = 'M';
        break;
      case 'Very Good Plus (VG+)':
        media = 'VG+';
        break;
      case 'Very Good (VG)':
        media = 'VG';
        break;
      case 'Good Plus (G+)':
        media = 'G+';
        break;
      case 'Good (G)':
        media = 'G';
        break;
      case 'Fair (F)':
        media = 'F';
        break;
      case 'Poor (P)':
        media = 'P';
        break;
      default:
    }

    switch (sleeve) {
      case 'Near Mint (NM or M-)':
        sleeve = 'NM';
        break;
      case 'Mint (M)':
        sleeve = 'M';
        break;
      case 'Very Good Plus (VG+)':
        sleeve = 'VG+';
        break;
      case 'Very Good (VG)':
        sleeve = 'VG';
        break;
      case 'Good Plus (G+)':
        sleeve = 'G+';
        break;
      case 'Good (G)':
        sleeve = 'G';
        break;
      case 'Fair (F)':
        sleeve = 'F';
        break;
      case 'Poor (P)':
        sleeve = 'P';
        break;
      default:
    }

    return `${media}/${sleeve}`;
  }


  generateOrderPDF = (orders) => {
    const { sellers } = this.state;
    const payments = orders.map(order => (
      <View style={styles.section}>
        <View>
          <Text style={styles.header}>{sellers.find(seller => seller.id === order.seller_id).name}
             : {order.external_order_id} ({order.sales_channel})
              ({order.order_items.length === 1 ?
                `${order.order_items.length} Item` : `${order.order_items.length} Items`})
          </Text>
        </View>
        { order.order_items.map((item, i) => (
          <Text style={styles.text}>{i + 1}.
           ({item.location ? item.location : 'N/A'})
            {(item.label || item.cat) ? `(${item.label ? item.label : null} - ${item.cat ? item.cat : null})` : null}
          ({this.getConditionString(item)})
            -{item.description ? item.description : item.title}
            <Link src={`https://www.discogs.com/sell/release/${item.release_id}`}>View Release</Link>
          </Text>
          ))}
        <Text style={styles.header}>{order.shipping_address_firstname} {order.shipping_address_lastname} -
          {this.getCountryLabel(order.shipping_address_country_code)} -
          {this.getServiceLabel(order.shipping_service, order.shipping_address_country_code)}
        </Text>
        {order.order_notes ? (
          <Text style={styles.textHighlighted}>{order.order_notes}
          </Text>
        ) : null }
      </View>
    ));

    MyDoc = null;

    MyDoc = (
      <Document title="Order">
        <Page size="A4" style={styles.page}>
          {payments}
        </Page>
      </Document>
    );

    const ordersToPrepare = [];
    orders.map((order) => {
      ordersToPrepare.push(order.id);
    });

    this.submitPrepareBatch(orders);
    this.forceUpdate();
  }

  copyShippingAddressToClipboard() {
    let returnAddress =
    `${this.state.selected.shipping_address_firstname} ${this.state.selected.shipping_address_lastname}\r\n`;
    returnAddress += `${this.state.selected.shipping_address_1}\r\n`;
    returnAddress += this.state.selected.shipping_address_2 ? `${this.state.selected.shipping_address_2}\r\n` : '';
    returnAddress += this.state.selected.shipping_address_3 ? `${this.state.selected.shipping_address_3}\r\n` : '';
    returnAddress += `${this.state.selected.shipping_address_city}\r\n`;
    returnAddress += this.state.selected.shipping_address_region ? `${this.state.selected.shipping_address_region}\r\n`
      : '';
    returnAddress += `${this.state.selected.shipping_address_postcode}\r\n`;
    const countryObj = countryList.find(obj => obj.value === this.state.selected.shipping_address_country_code);
    if (countryObj !== undefined) {
      returnAddress += countryObj.label;
    }
    const inp = document.createElement('textarea');
    document.body.appendChild(inp);
    inp.value = returnAddress;
    inp.select();
    document.execCommand('copy');
    inp.remove();
    // return returnAddress;
  }

  handleWeightChange(e) {
    let weight = e.target.value;
    let newSize = null;

    if (this.state.selectedSize === 'LL' && weight > 749) {
      if (this.state.selected.shipping_address_country_code === 'GB') {
        newSize = 'SP';
      } else {
        weight = 749;
      }
    } else if (weight > 1999) {
      weight = 1999;
    }
    // console.log(getServicePrice(
    //   e.target.value,
    //   this.state.selected.shipping_service,
    //   this.getCountryLabel(this.state.selected.shipping_address_country_code),
    //   this.state.selectedSize,
    // ));
    this.setState({
      selectedActWeight: weight,
      selectedSize: newSize || this.state.selectedSize,
      selectedMMPrice: getMMServicePrice(
        weight,
        this.state.selected.shipping_service,
        this.getCountryLabel(this.state.selected.shipping_address_country_code),
        newSize || this.state.selectedSize,
      ),
      selectedActualPrice: getServicePrice(
        weight,
        this.state.selected.shipping_service,
        this.getCountryLabel(this.state.selected.shipping_address_country_code),
        newSize || this.state.selectedSize,
      ),
    });
  }

  handleItemChange(e) {
    const selected = { ...this.state.selected };
    selected[e.target.name] = e.target.value;
    this.setState({ selected });
  }

  saveOrderChanges() {
    const selected = { ...this.state.selected };
    console.log('SAVE CHANGES');
    console.log(selected);
  }

  handleSizeChange(e) {
    // console.log('PRICE:');
    // console.log(getServicePrice(
    //   this.state.selectedActWeight,
    //   this.state.selected.shipping_service,
    //   this.getCountryLabel(this.state.selected.shipping_address_country_code),
    //   e,
    // ));
    let weight = this.state.selectedActWeight;
    if (e === 'LL' && weight > 749) {
      weight = 749;
    } else if (weight > 1999) {
      weight = 1999;
    }
    this.setState({
      selectedSize: e,
      selectedActWeight: weight,
      selectedMMPrice: getMMServicePrice(
        weight,
        this.state.selected.shipping_service,
        this.getCountryLabel(this.state.selected.shipping_address_country_code),
        e,
      ),
      selectedActualPrice: getServicePrice(
        weight,
        this.state.selected.shipping_service,
        this.getCountryLabel(this.state.selected.shipping_address_country_code),
        e,
      ),
    });
  }

  addToShipmentBatch() {
    const order = this.state.selected;
    order.country = order.shipping_address_country_code;
    // order.country = this.getCountryLabel(order.shipping_address_country_code);
    const shipmentBatchItem = {
      order,
      fulfillmentId: order.id,
      weight: this.state.selectedActWeight,
      actual_shipping_price: this.state.selectedActualPrice,
      actual_size: this.state.selectedSize,
      mm_shipping_cost: this.state.selectedMMPrice,
    };

    this.setState({
      shipmentBatch: [...this.state.shipmentBatch, shipmentBatchItem],
    });
  }

  removeFromShipmentBatch() {
    const order = this.state.selected;

    const shipmentBatch = [...this.state.shipmentBatch]; // make a separate copy of the array
    const index = shipmentBatch.indexOf(shipmentBatch.find(shipment => shipment.fulfillmentId === order.id));
    if (index !== -1) {
      shipmentBatch.splice(index, 1);
      this.setState({ shipmentBatch });
    }
  }

  async submitShipmentBatch() {
    await axios.post(`${URL}/admin/fulfillment/orders/ship`, {
      orders: this.state.shipmentBatch,
    }, { headers: { Authorization: localStorage.getItem('mm_admin') } })
      .then((res) => {
      // Update react-table
        const newData = [...this.state.data];
        res.data.orders.map((id) => {
          const index = newData.findIndex(obj => obj.id === id.id);
          if (index !== -1) {
            newData.splice(index, 1);
          }
        });

        this.setState({
          data: newData,
          shipmentBatch: [],
        });
      });
    return true;
  }

  async submitPrepareBatch(orders) {
    await axios.post(`${URL}/admin/fulfillment/orders/prepare`, {
      orders,
    }, { headers: { Authorization: localStorage.getItem('mm_admin') } })
      .then((res) => {
      // Update react-table
        const newData = [...this.state.data];
        res.data.orders.map((id) => {
          const index = newData.findIndex(obj => obj.id === id.id);
          if (index !== -1) {
            newData[index].status = 'Preparing';
          }
        });

        this.setState({
          data: newData,
        });
      });
    return true;
  }

  isDomestic = (countryCode) => {
    if (countryCode === 'GBR') {
      return true;
    }
    return false;
  }

  render() {
    const ordersColumns = [
      {
        Header: 'MMF ID',
        id: 'id',
        accessor: 'id',
        // width: getColumnWidth(this.state.data, 'id', 'MMF ID'),
      }, {
        Header: 'Seller',
        id: 'seller_id',
        accessor: d => this.state.sellers.find(seller => seller.id === d.seller_id).name,
        width: getColumnWidth(this.state.data, 'seller_id', 'Order Id'),
        Filter: ({ filter, onChange }) =>
          (
            <select
              onChange={event => onChange(event.target.value)}
              style={{ width: '100%' }}
              value={filter ? filter.value : 'all'}
            >
              <option value="">All Sellers</option>
              {this.state.sellers.map(seller => <option value={seller.id}>{seller.name}</option>)}
            </select>),
      }, {
        Header: 'Channel',
        id: 'sales_channel',
        width: getColumnWidth(this.state.data, 'sales_channel', 'Channel'),
        accessor: d => d.sales_channel,
        Filter: ({ filter, onChange }) =>
          (
            <select
              onChange={event => onChange(event.target.value)}
              style={{ width: '100%' }}
              value={filter ? filter.value : 'all'}
            >
              <option value="">All</option>
              <option value="Discogs">Discogs</option>
              <option value="eBay">eBay</option>
            </select>),
      }, {
        Header: 'Order Id',
        id: 'external_order_id',
        accessor: 'external_order_id',
        width: getColumnWidth(this.state.data, 'external_order_id', 'Order Id'),

      }, {
        Header: 'Summary',
        id: 'summary',
        sortable: false,
        // accessor: d => {
        //   if (d.items.length > 1) {
        //     return d.items.length+' Items';
        //   } else {
        //     return d.items.length+' Item';
        //   }
        // }
        Cell: row => (
          <div>
            <span>
              <b>{(row.original.order_items.length > 1) ? `${row.original.order_items.length} Items:`
              : `${row.original.order_items.length} Item:`}
              </b>
            </span>
            {row.original.order_items.map(i =>
              <tr>{i ? i.description : '' }</tr>)}
          </div>
        ),
      }, {
        Header: 'Status',
        id: 'status',
        width: getColumnWidth(this.state.data, 'status', 'Status'),
        accessor: d => d.status,
        Filter: ({ filter, onChange }) =>
          (
            <select
              onChange={event => onChange(event.target.value)}
              style={{ width: '100%' }}
              value={filter ? filter.value : 'all'}
            >
              <option value="">All</option>
              <option value="new">New</option>
              <option value="preparing">Preparing</option>
              <option value="queried">Queried</option>
            </select>),
      }, {
        Header: 'Date',
        id: 'created_date',
        filterable: false,
        width: getColumnWidth(this.state.data, 'created_date', 'Date'),
        // Filter: ({ filter, onChange }) =>
        //             <IntervalDatePickerField
        //               onChange={event => onChange(event.target.value)}
        //                 style={{ width: "100%" }}
        //                 value={filter ? filter.value : "all"}
        //             />,
        Cell: row => (
          <div>
            <span>{new Date(row.original.created_date).toLocaleDateString()}</span>
          </div>
        ),
      },
      // ,{
      //   Header: 'Fulfillment Status',
      //   id: 'fulfillment_status',
      //   accessor: 'fulfillment_status',
      //   show: this.props.user.fulfillment
      // }
    ];

    return (
      <Col>
        <Card
          style={{
                height: '500px',
              }}
        >
          <CardBody>
            <div>
              <Button color="primary" onClick={() => this.generateOrderPDF(this.state.data)}>Generate PDF</Button>
              {/* <PDFDownloadLink
                className="btn btn-primary"
                document={MyDoc}
                fileName="somename.pdf"
              >Download
              </PDFDownloadLink> */}
              <PDFDownloadLink
                className="btn btn-primary"
                document={MyDoc}
                fileName="somename.pdf"
              >
                {({
                  loading,
                }) => (loading ? 'Loading document...' : 'Download')}
              </PDFDownloadLink>
            </div>
            {/* <ReactTable
                  data={this.state.data}
                  columns={ordersColumns}
                  showPagination= {false}
                  className="-striped -highlight -responsive"
                  getTrProps={(state, rowInfo) => {
                  if (rowInfo != undefined) {
                  return {
                  onClick: (e) => {
                  this.setState({
                  selected: rowInfo.original
                })
              },
              style: {
              background: rowInfo.original === this.state.selected ? '#00afec' : 'white',
              color: rowInfo.original === this.state.selected ? 'white' : 'black'
            }
          }
        } else {
        return {}
      }
    }}
    style={{
    height: "400px" // This will force the table body to overflow and scroll, since there is not enough room
  }}
/> */}
            <ReactTable
              style={{
    height: '400px', // This will force the table body to overflow and scroll, since there is not enough room
  }}
              data={this.state.data} // should default to []
              columns={ordersColumns}
              pages={this.state.pages} // should default to -1 (which means we don't know how many pages we have)
              loading={this.state.loading}
              manual
              filterable
              multiSort={false}
              defaultPageSize={50}
              defaultSorted={[
    {
      id: 'created_date',
      desc: true,
    },
  ]}
              className="-striped -highlight -responsive"
              getTrProps={(state, rowInfo) => {
    if (rowInfo !== undefined) {
      return {
        onClick: () => {
          const currentShipment = this.state.shipmentBatch.find(shipment =>
            shipment.fulfillmentId === rowInfo.original.id);

          this.setState({
            selected: rowInfo.original,
            selectedOriginal: rowInfo.original,
            isEditing: false,
            selectedActWeight: currentShipment ? currentShipment.weight : '',
            selectedSize: currentShipment ? currentShipment.actual_size : rowInfo.original.package_size,
            selectedMMPrice: currentShipment ? currentShipment.mm_shipping_cost : 0,
            selectedActualPrice: currentShipment ? currentShipment.actual_shipping_price : 0,
          });
          // this.setState({
          //   selected: rowInfo.original,
          //   selectedActWeight: this.state.shipmentBatch.find(shipment =>
          //     shipment.fulfillmentId === rowInfo.original.id) ? this.state.shipmentBatch.find(shipment =>
          //       shipment.fulfillmentId === rowInfo.original.id).weight : '',
          //   selectedSize: this.state.shipmentBatch.find(shipment =>
          //     shipment.fulfillmentId === rowInfo.original.id) ? this.state.shipmentBatch.find(shipment =>
          //       shipment.fulfillmentId === rowInfo.original.id).actual_size : rowInfo.original.package_size,
          // });
        },
        style: {
          background: rowInfo.original === this.state.selected ? '#00afec' : 'white',
          color: rowInfo.original === this.state.selected ? 'white' : 'black',
        },
      };
    }
      return {};
  }} // informs React Table that you'll be handling sorting and pagination server-side
              onFetchData={(state) => {
    // show the loading overlay
    MyDoc = null;
    this.setState({ loading: true });
    axios.post(`${URL}/admin/fulfillment/orders/completed`, {
      page: state.page,
      pageSize: state.pageSize,
      sorted: state.sorted,
      filtered: state.filtered,
    }, { headers: { Authorization: localStorage.getItem('mm_admin') } })
    .then((res) => {
      // Update react-table
      this.setState({
        data: res.data.orders,
        pages: res.data.pages,
        loading: false,
      });
    });
  }}
            />
            {/* <Button color="primary" onClick={() => this.handleButtonClick()}>primary</Button> */}
          </CardBody>
        </Card>
        {this.state.selected !== undefined ? (
          <CardDeck>
            <Card>
              <CardBody>
                <div className="project-summary">
                  <div className="card__title">
                    <h5 className="bold-text">{`${this.state.selected.external_order_id}`}</h5>
                    <Button
                      color="primary"
                      size="sm"
                      disabled={this.state.isEditing}
                      onClick={() => this.setState(prevState => ({ isEditing: !prevState.isEditing }))}
                    >Edit
                    </Button>
                    {this.state.isEditing ? (
                      <Button
                        color="danger"
                        size="sm"
                        onClick={() =>
                            this.setState(prevState => ({
                              isEditing: !prevState.isEditing,
                              selected: prevState.selectedOriginal,
                            }))
                          }
                      >Cancel
                      </Button>
                      ) : null }
                    { isEquals(this.state.selectedOriginal, this.state.selected) ? (
                          null
                        ) :
                          <Button
                            color="primary"
                            size="sm"
                            onClick={() => this.saveOrderChanges()}
                          >Save Changes
                          </Button> }
                  </div>
                  <div className="project-summary__stats">
                    <div className="project-summary__stat">
                      <table className="project-summary__info">
                        <tbody>
                          <tr>
                            <th>Order date:</th>
                            <td>{new Date(this.state.selected.created_date).toLocaleDateString()}</td>
                          </tr>
                          <tr>
                            <th>Shipped date:</th>
                            <td>{new Date(this.state.selected.shipped_date).toLocaleDateString()}</td>
                          </tr>
                          <tr>
                            <th>Order Status:</th>
                            <td>{this.state.selected.status}</td>
                          </tr>
                          <tr>
                            <th>Notes:</th>
                            <td><b>{this.state.selected.order_notes}</b></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="project-summary__stat">
                      <table className="project-summary__info">
                        <tbody>
                          <tr>
                            <th>Service:</th>
                            <td>{this.getServiceLabel(
                              this.state.selected.shipping_service,
                              this.state.selected.shipping_address_country_code,
                            )}
                            </td>
                          </tr>
                          <tr>
                            <th>Weight (est.):</th>
                            <td>{this.state.selected.estimated_weight}g</td>
                          </tr>
                          <tr>
                            <th>Weight (act.):</th>
                            <td>{this.state.selected.actual_weight}g</td>
                          </tr>
                          <tr>
                            <th>Price (est.):</th>
                            <td>{currencyFormat.format(this.state.selected.estimated_shipping_price)}</td>
                          </tr>
                          <tr>
                            <th>Price (act.):</th>
                            <td>{currencyFormat.format(this.state.selected.actual_shipping_price)}</td>
                          </tr>
                          <tr>
                            <th>Tracking:</th>
                            <td>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`https://www.royalmail.com/track-your-item?trackNumber=
                                ${this.state.selected.tracking_number}`}
                              >{this.state.selected.tracking_number}
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <th>MM Cost:</th>
                            <td>{currencyFormat.format(this.state.selectedMMPrice)}</td>
                          </tr>
                          <tr>
                            <th />
                            <td>
                              <button
                                className="btn btn-primary"
                                type="button"
                                disabled={!this.state.selectedActWeight}
                                onClick={this.state.shipmentBatch.find(shipment =>
                                  shipment.fulfillmentId === this.state.selected.id)
                                  ? this.removeFromShipmentBatch : this.addToShipmentBatch}
                              >{this.state.shipmentBatch.find(shipment =>
                                shipment.fulfillmentId === this.state.selected.id)
                                ? 'Remove From Batch' : 'Add To Batch'}
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="project-summary__stat">
                      <table className="project-summary__info">
                        <tbody>
                          {/* <tr>
                            <th>Shipping Address:</th>
                            <td>{this.getShippingAddress()}</td>
                          </tr> */}
                          <tr>
                            <th>First Name:</th>
                            {this.state.isEditing ? (
                              <td>
                                <input
                                  type="text"
                                  name="shipping_address_firstname"
                                  value={this.state.selected.shipping_address_firstname}
                                  onChange={this.handleItemChange}
                                />
                              </td>
                            ) : <td>{this.state.selected.shipping_address_firstname}</td> }
                          </tr>
                          <tr>
                            <th>Last Name:</th>
                            {this.state.isEditing ? (
                              <td>
                                <input
                                  type="text"
                                  name="shipping_address_lastname"
                                  value={this.state.selected.shipping_address_lastname}
                                  onChange={this.handleItemChange}
                                />
                              </td>
                            ) : <td>{this.state.selected.shipping_address_lastname}</td> }
                          </tr>
                          <tr>
                            <th>Address Line 1:</th>
                            {this.state.isEditing ? (
                              <td>
                                <input
                                  type="text"
                                  name="shipping_address_1"
                                  value={this.state.selected.shipping_address_1}
                                  onChange={this.handleItemChange}
                                />
                              </td>
                            ) : <td>{this.state.selected.shipping_address_1}</td> }
                          </tr>
                          {this.state.isEditing ? (
                            <tr>
                              <th>Address Line 2:</th>
                              <td>
                                <input
                                  type="text"
                                  name="shipping_address_2"
                                  value={this.state.selected.shipping_address_2}
                                  onChange={this.handleItemChange}
                                />
                              </td>
                            </tr>
                          ) : (
                            this.state.selected.shipping_address_2 ? (
                              <tr>
                                <th>Address Line 2:</th>
                                <td>{this.state.selected.shipping_address_2}</td>
                              </tr>
                          ) : null
                        )}
                          {this.state.isEditing ? (
                            <tr>
                              <th>Address Line 3:</th>
                              <td>
                                <input
                                  type="text"
                                  name="shipping_address_3"
                                  value={this.state.selected.shipping_address_3}
                                  onChange={this.handleItemChange}
                                />
                              </td>
                            </tr>
                          ) : (
                            this.state.selected.shipping_address_3 ? (
                              <tr>
                                <th>Address Line 3:</th>
                                <td>{this.state.selected.shipping_address_3}</td>
                              </tr>
                          ) : null
                        )}
                          <tr>
                            <th>City:</th>
                            {this.state.isEditing ? (
                              <td>
                                <input
                                  type="text"
                                  name="shipping_address_city"
                                  value={this.state.selected.shipping_address_city}
                                  onChange={this.handleItemChange}
                                />
                              </td>
                            ) : <td>{this.state.selected.shipping_address_city}</td> }
                          </tr>
                          {this.state.isEditing ? (
                            <tr>
                              <th>Conty:</th>
                              <td>
                                <input
                                  type="text"
                                  name="shipping_address_region"
                                  value={this.state.selected.shipping_address_region}
                                  onChange={this.handleItemChange}
                                />
                              </td>
                            </tr>
                          ) : (
                            this.state.selected.shipping_address_region ? (
                              <tr>
                                <th>County:</th>
                                <td>{this.state.selected.shipping_address_region}</td>
                              </tr>
                          ) : null
                        )}
                          <tr>
                            <th>Postcode:</th>
                            {this.state.isEditing ? (
                              <td>
                                <input
                                  type="text"
                                  name="shipping_address_postcode"
                                  value={this.state.selected.shipping_address_postcode}
                                  onChange={this.handleItemChange}
                                />
                              </td>
                            ) : <td>{this.state.selected.shipping_address_postcode}</td> }
                          </tr>
                          <tr>
                            <th>Country:</th>
                            <td>{this.getCountryLabel(this.state.selected.shipping_address_country_code)}</td>
                          </tr>
                          <Button
                            color="primary"
                            size="sm"
                            onClick={() => this.copyShippingAddressToClipboard()}
                          >Copy Address
                          </Button>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <hr />
                </div>
              </CardBody>
            </Card>
          </CardDeck>) : null }
      </Col>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
});

// export default connect()(SidebarContent);
export default connect(mapStateToProps)(AllOrdersTable);
