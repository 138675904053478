import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import AmazonOrdersTable from './components/AmazonOrdersTable';
// import NewItemsTable from './components/NewItemsTable';

const AmazonOrders = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h5 className="page-title">Orders / Amazon</h5>
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <AmazonOrdersTable />
      </Col>
    </Row>
  </Container>
);

export default AmazonOrders;
