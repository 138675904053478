export const iso2codes = {
  BD: 'BGD',
  BE: 'BEL',
  BF: 'BFA',
  BG: 'BGR',
  BA: 'BIH',
  BB: 'BRB',
  WF: 'WLF',
  BL: 'BLM',
  BM: 'BMU',
  BN: 'BRN',
  BO: 'BOL',
  BH: 'BHR',
  BI: 'BDI',
  BJ: 'BEN',
  BT: 'BTN',
  JM: 'JAM',
  BV: 'BVT',
  BW: 'BWA',
  WS: 'WSM',
  BQ: 'BES',
  BR: 'BRA',
  BS: 'BHS',
  JE: 'JEY',
  BY: 'BLR',
  BZ: 'BLZ',
  RU: 'RUS',
  RW: 'RWA',
  RS: 'SRB',
  TL: 'TLS',
  RE: 'REU',
  TM: 'TKM',
  TJ: 'TJK',
  RO: 'ROU',
  TK: 'TKL',
  GW: 'GNB',
  GU: 'GUM',
  GT: 'GTM',
  GS: 'SGS',
  GR: 'GRC',
  GQ: 'GNQ',
  GP: 'GLP',
  JP: 'JPN',
  GY: 'GUY',
  GG: 'GGY',
  GF: 'GUF',
  GE: 'GEO',
  GD: 'GRD',
  GB: 'GBR',
  GA: 'GAB',
  SV: 'SLV',
  GN: 'GIN',
  GM: 'GMB',
  GL: 'GRL',
  GI: 'GIB',
  GH: 'GHA',
  OM: 'OMN',
  TN: 'TUN',
  JO: 'JOR',
  HR: 'HRV',
  HT: 'HTI',
  HU: 'HUN',
  HK: 'HKG',
  HN: 'HND',
  HM: 'HMD',
  VE: 'VEN',
  PR: 'PRI',
  PS: 'PSE',
  PW: 'PLW',
  PT: 'PRT',
  SJ: 'SJM',
  PY: 'PRY',
  IQ: 'IRQ',
  PA: 'PAN',
  PF: 'PYF',
  PG: 'PNG',
  PE: 'PER',
  PK: 'PAK',
  PH: 'PHL',
  PN: 'PCN',
  PL: 'POL',
  PM: 'SPM',
  ZM: 'ZMB',
  EH: 'ESH',
  EE: 'EST',
  EG: 'EGY',
  ZA: 'ZAF',
  EC: 'ECU',
  IT: 'ITA',
  VN: 'VNM',
  SB: 'SLB',
  ET: 'ETH',
  SO: 'SOM',
  ZW: 'ZWE',
  SA: 'SAU',
  ES: 'ESP',
  ER: 'ERI',
  ME: 'MNE',
  MD: 'MDA',
  MG: 'MDG',
  MF: 'MAF',
  MA: 'MAR',
  MC: 'MCO',
  UZ: 'UZB',
  MM: 'MMR',
  ML: 'MLI',
  MO: 'MAC',
  MN: 'MNG',
  MH: 'MHL',
  MK: 'MKD',
  MU: 'MUS',
  MT: 'MLT',
  MW: 'MWI',
  MV: 'MDV',
  MQ: 'MTQ',
  MP: 'MNP',
  MS: 'MSR',
  MR: 'MRT',
  IM: 'IMN',
  UG: 'UGA',
  TZ: 'TZA',
  MY: 'MYS',
  MX: 'MEX',
  IL: 'ISR',
  FR: 'FRA',
  IO: 'IOT',
  SH: 'SHN',
  FI: 'FIN',
  FJ: 'FJI',
  FK: 'FLK',
  FM: 'FSM',
  FO: 'FRO',
  NI: 'NIC',
  NL: 'NLD',
  NO: 'NOR',
  NA: 'NAM',
  VU: 'VUT',
  NC: 'NCL',
  NE: 'NER',
  NF: 'NFK',
  NG: 'NGA',
  NZ: 'NZL',
  NP: 'NPL',
  NR: 'NRU',
  NU: 'NIU',
  CK: 'COK',
  XK: 'XKX',
  CI: 'CIV',
  CH: 'CHE',
  CO: 'COL',
  CN: 'CHN',
  CM: 'CMR',
  CL: 'CHL',
  CC: 'CCK',
  CA: 'CAN',
  CG: 'COG',
  CF: 'CAF',
  CD: 'COD',
  CZ: 'CZE',
  CY: 'CYP',
  CX: 'CXR',
  CR: 'CRI',
  CW: 'CUW',
  CV: 'CPV',
  CU: 'CUB',
  SZ: 'SWZ',
  SY: 'SYR',
  SX: 'SXM',
  KG: 'KGZ',
  KE: 'KEN',
  SS: 'SSD',
  SR: 'SUR',
  KI: 'KIR',
  KH: 'KHM',
  KN: 'KNA',
  KM: 'COM',
  ST: 'STP',
  SK: 'SVK',
  KR: 'KOR',
  SI: 'SVN',
  KP: 'PRK',
  KW: 'KWT',
  SN: 'SEN',
  SM: 'SMR',
  SL: 'SLE',
  SC: 'SYC',
  KZ: 'KAZ',
  KY: 'CYM',
  SG: 'SGP',
  SE: 'SWE',
  SD: 'SDN',
  DO: 'DOM',
  DM: 'DMA',
  DJ: 'DJI',
  DK: 'DNK',
  VG: 'VGB',
  DE: 'DEU',
  YE: 'YEM',
  DZ: 'DZA',
  US: 'USA',
  UY: 'URY',
  YT: 'MYT',
  UM: 'UMI',
  LB: 'LBN',
  LC: 'LCA',
  LA: 'LAO',
  TV: 'TUV',
  TW: 'TWN',
  TT: 'TTO',
  TR: 'TUR',
  LK: 'LKA',
  LI: 'LIE',
  LV: 'LVA',
  TO: 'TON',
  LT: 'LTU',
  LU: 'LUX',
  LR: 'LBR',
  LS: 'LSO',
  TH: 'THA',
  TF: 'ATF',
  TG: 'TGO',
  TD: 'TCD',
  TC: 'TCA',
  LY: 'LBY',
  VA: 'VAT',
  VC: 'VCT',
  AE: 'ARE',
  AD: 'AND',
  AG: 'ATG',
  AF: 'AFG',
  AI: 'AIA',
  VI: 'VIR',
  IS: 'ISL',
  IR: 'IRN',
  AM: 'ARM',
  AL: 'ALB',
  AO: 'AGO',
  AQ: 'ATA',
  AS: 'ASM',
  AR: 'ARG',
  AU: 'AUS',
  AT: 'AUT',
  AW: 'ABW',
  IN: 'IND',
  AX: 'ALA',
  AZ: 'AZE',
  IE: 'IRL',
  ID: 'IDN',
  UA: 'UKR',
  QA: 'QAT',
  MZ: 'MOZ',
};


export const countryList = [
  {
    label: 'Afghanistan',
    value: 'AFG',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Aland Islands',
    value: 'ALA',
    zone: 1,
    dhlZone: 10,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Albania',
    value: 'ALB',
    zone: 'EU',
    dhlZone: 7,
    services: [
      'MTA',
      'MTC',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Algeria',
    value: 'DZA',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Andorra',
    value: 'AND',
    zone: 'EU',
    dhlZone: 7,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Angola',
    value: 'AGO',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Anguilla',
    value: 'AIA',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Antigua / Barbuda',
    value: 'ATG',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Argentina',
    value: 'ARG',
    zone: 1,
    dhlZone: 9,
    services: [
      'MTA',
      'MTC',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Armenia',
    value: 'ARM',
    zone: 'EU',
    dhlZone: 7,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Aruba',
    value: 'ABW',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Ascension Island',
    value: 'SHN',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Australia',
    value: 'AUS',
    zone: 2,
    dhlZone: 8,
    services: [
      'MP1',
      'MTI',
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Austria',
    value: 'AUT',
    zone: 'EU',
    dhlZone: 1,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Azerbaijan',
    value: 'AZE',
    zone: 'EU',
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Bahamas',
    value: 'BHS',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Bahrain',
    value: 'BHR',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Bangladesh',
    value: 'BGD',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Barbados',
    value: 'BRB',
    zone: 1,
    dhlZone: 9,
    services: [
      'MTA',
      'MTC',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Belarus',
    value: 'BLR',
    zone: 'EU',
    dhlZone: 9,
    services: [
      'MTA',
      'MTC',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Belgium',
    value: 'BEL',
    zone: 'EU',
    dhlZone: 1,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Belize',
    value: 'BLZ',
    zone: 1,
    dhlZone: 9,
    services: [
      'MTA',
      'MTC',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Benin',
    value: 'BEN',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Bermuda',
    value: 'BMU',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Bhutan',
    value: 'BTN',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Bolivia',
    value: 'BOL',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Bonaire',
    value: 'BES',
    zone: 1,
    dhlZone: 10,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Bosnia Hertzegovina',
    value: 'BIH',
    zone: 'EU',
    dhlZone: 7,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Botswana',
    value: 'BWA',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Brazil',
    value: 'BRA',
    zone: 1,
    dhlZone: 7,
    services: [
      'MP1',
      'MTI',
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'British Indian Ocean Territory',
    value: 'IOT',
    zone: 1,
    dhlZone: 10,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'British Virgin Islands',
    value: 'VGB',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Brunei',
    value: 'BRN',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Bulgaria',
    value: 'BGR',
    zone: 'EU',
    dhlZone: 2,
    services: [
      'MTA',
      'MTC',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Burkina Faso',
    value: 'BFA',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Burundi',
    value: 'BDI',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Cambodia',
    value: 'KHM',
    zone: 1,
    dhlZone: 9,
    services: [
      'MTA',
      'MTC',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Cameroon',
    value: 'CMR',
    zone: 1,
    dhlZone: 9,
    services: [
      'MTA',
      'MTC',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Canada',
    value: 'CAN',
    zone: 1,
    dhlZone: 8,
    services: [
      // 'MTA',
      'MTC',
      'MP1',
      'MP5',
      'MTI',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Cape Verde',
    value: 'CPV',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Cayman Islands',
    value: 'CYM',
    zone: 1,
    dhlZone: 9,
    services: [
      'MTA',
      'MTC',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Central African Republic',
    value: 'CAF',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Chad',
    value: 'TCD',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Chile',
    value: 'CHL',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: "China (People's Republic of)",
    value: 'CHN',
    zone: 1,
    dhlZone: 7,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'China',
    value: 'CHN',
    zone: 1,
    dhlZone: 7,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Christmas Island (Indian Ocean)',
    value: 'CXR',
    zone: 2,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Christmas Island (Pacific Ocean)',
    value: 'CXR',
    zone: 2,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Colombia',
    value: 'COL',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Comoros Islands',
    value: 'COM',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Congo ( Dem. Rep of)',
    value: 'COD',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Congo ( Rep of)',
    value: 'COG',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Cook Islands',
    value: 'COK',
    zone: 2,
    dhlZone: 9,
    services: [
      'MTA',
      'MTC',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Costa Rica',
    value: 'CRI',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Croatia',
    value: 'HRV',
    zone: 'EU',
    dhlZone: 2,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Cuba',
    value: 'CUB',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Curacao',
    value: 'CUW',
    zone: 1,
    dhlZone: 10,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Cyprus',
    value: 'CYP',
    zone: 'EU',
    dhlZone: 2,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Czech Republic',
    value: 'CZE',
    zone: 'EU',
    dhlZone: 2,
    services: [
      'MTA',
      'MTC',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Denmark',
    value: 'DNK',
    zone: 'EU',
    dhlZone: 3,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Djibouti',
    value: 'DJI',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Dominica',
    value: 'DMA',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Dominican Republic',
    value: 'DOM',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Ecuador',
    value: 'ECU',
    zone: 1,
    dhlZone: 9,
    services: [
      'MTA',
      'MTC',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Egypt',
    value: 'EGY',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'El Salvador',
    value: 'SLV',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Equatorial Guinea',
    value: 'GNQ',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Eritrea',
    value: 'ERI',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Estonia',
    value: 'EST',
    zone: 'EU',
    dhlZone: 2,
    services: [
      'MP1',
      'MTI',
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Ethiopia',
    value: 'ETH',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Falkland Islands',
    value: 'FLK',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Faroe Islands',
    value: 'FRO',
    zone: 'EU',
    dhlZone: 7,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Fiji',
    value: 'FJI',
    zone: 2,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Finland',
    value: 'FIN',
    zone: 'EU',
    dhlZone: 3,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'France',
    value: 'FRA',
    zone: 'EU',
    dhlZone: 1,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'French Guiana',
    value: 'GUF',
    zone: 1,
    dhlZone: 10,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'French Polynesia',
    value: 'PYF',
    zone: 2,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'French South Antarctic Territory',
    value: 'ATF',
    zone: 2,
    dhlZone: 10,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Gabon',
    value: 'GAB',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Gambia',
    value: 'GMB',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Georgia',
    value: 'GEO',
    zone: 'EU',
    dhlZone: 7,
    services: [
      'MTA',
      'MTC',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Germany',
    value: 'DEU',
    zone: 'EU',
    dhlZone: 0,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Ghana',
    value: 'GHA',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Gibraltar',
    value: 'GIB',
    zone: 'EU',
    dhlZone: 7,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Greece',
    value: 'GRC',
    zone: 'EU',
    dhlZone: 1,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Greenland',
    value: 'GRL',
    zone: 'EU',
    dhlZone: 7,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Grenada',
    value: 'GRL',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Guadeloupe',
    value: 'GRD',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Guatemala',
    value: 'GTM',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Guinea',
    value: 'GIN',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Guinea-Bissau',
    value: 'GNB',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Guyana',
    value: 'GUY',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Haiti',
    value: 'HTI',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Honduras',
    value: 'HND',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Hong Kong',
    value: 'HKG',
    zone: 1,
    dhlZone: 7,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Hungary',
    value: 'HUN',
    zone: 'EU',
    dhlZone: 2,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Iceland',
    value: 'ISL',
    zone: 'EU',
    dhlZone: 4,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'India',
    value: 'IND',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP1',
      'MTI',
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Indonesia',
    value: 'IDN',
    zone: 1,
    dhlZone: 9,
    services: [
      'MTA',
      'MTC',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Iran (Islamic Republic of)',
    value: 'IRN',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Iraq',
    value: 'IRQ',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Ireland',
    value: 'IRL',
    zone: 'EU',
    dhlZone: 3,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Israel',
    value: 'ISR',
    zone: 1,
    dhlZone: 8,
    services: [
      'MP1',
      'MTI',
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Italy',
    value: 'ITA',
    zone: 'EU',
    dhlZone: 3,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: "Cote d'Ivoire (Ivory Coast)",
    value: 'CIV',
    zone: 1,
    dhlZone: 10,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Jamaica',
    value: 'JAM',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Japan',
    value: 'JPN',
    zone: 1,
    dhlZone: 8,
    services: [
      'MTA',
      'MTC',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Jordan',
    value: 'JOR',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Kazakhstan',
    value: 'KAZ',
    zone: 'EU',
    dhlZone: 9,
    services: [
      'MTA',
      'MTC',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Kenya',
    value: 'KEN',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Kiribati',
    value: 'KIR',
    zone: 2,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Kosovo',
    value: 'KOS',
    zone: 'EU',
    dhlZone: 7,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Kuwait',
    value: 'KWT',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Kyrgyzstan',
    value: 'KGZ',
    zone: 'EU',
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: "Laos (People's Democratic Republic of)",
    value: 'LAO',
    zone: 2,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Latvia',
    value: 'LVA',
    zone: 'EU',
    dhlZone: 2,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Lebanon',
    value: 'LBN',
    zone: 1,
    dhlZone: 9,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Lesotho',
    value: 'LSO',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Liberia',
    value: 'LBR',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Libya',
    value: 'LBY',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Liechtenstein',
    value: 'LIE',
    zone: 'EU',
    dhlZone: 7,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Lithuania',
    value: 'LTU',
    zone: 'EU',
    dhlZone: 2,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Luxembourg',
    value: 'LUX',
    zone: 'EU',
    dhlZone: 1,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Macao',
    value: 'MAC',
    zone: 2,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Macedonia',
    value: 'MKD',
    zone: 'EU',
    dhlZone: 7,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Madagascar',
    value: 'MDG',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Mahore (also known as Mayotte)',
    value: 'MYT',
    zone: 1,
    dhlZone: 10,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Malawi',
    value: 'MWI',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Malaysia',
    value: 'MYS',
    zone: 1,
    dhlZone: 7,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Maldives',
    value: 'MDV',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Mali',
    value: 'MLI',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Malta',
    value: 'MLT',
    zone: 'EU',
    dhlZone: 2,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Martinique',
    value: 'MTQ',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Mauritania',
    value: 'MRT',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Mauritius',
    value: 'MUS',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Mexico',
    value: 'MEX',
    zone: 1,
    dhlZone: 7,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Moldova',
    value: 'MDA',
    zone: 'EU',
    dhlZone: 9,
    services: [
      'MTA',
      'MTC',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Monaco',
    value: 'MCO',
    zone: 1,
    dhlZone: 1,
    services: [
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Mongolia',
    value: 'MNG',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Montenegro',
    value: 'MNE',
    zone: 'EU',
    dhlZone: 7,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Montserrat',
    value: 'MSR',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Morocco',
    value: 'MAR',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Mozambique',
    value: 'MOZ',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Myanmar',
    value: 'MMR',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Namibia',
    value: 'NAM',
    zone: 2,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Nauru Island',
    value: 'NRU',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Nepal',
    value: 'NPL',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Netherlands',
    value: 'NLD',
    zone: 'EU',
    dhlZone: 1,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'New Caledonia',
    value: 'NCL',
    zone: 2,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'New Zealand',
    value: 'NZL',
    zone: 2,
    dhlZone: 8,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Nicaragua',
    value: 'NIC',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Niger Republic',
    value: 'NER',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Nigeria',
    value: 'NGA',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Niue Island',
    value: 'NIU',
    zone: 2,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: "North Korea (People's Democratic Republic of )",
    value: 'PRK',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Norway',
    value: 'NOR',
    zone: 'EU',
    dhlZone: 4,
    services: [
      'MP1',
      'MTI',
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Oman',
    value: 'OMN',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Pakistan',
    value: 'PAK',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Palau (known also as Belau)',
    value: 'PLW',
    zone: 2,
    dhlZone: 10,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Panama',
    value: 'PAN',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Papua New Guinea',
    value: 'PNG',
    zone: 2,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Paraguay',
    value: 'PRY',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Peru',
    value: 'PER',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Philippines',
    value: 'PHL',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Pitcairn Island',
    value: 'PCN',
    zone: 2,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Poland',
    value: 'POL',
    zone: 'EU',
    dhlZone: 2,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Portugal',
    value: 'PRT',
    zone: 'EU',
    dhlZone: 1,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Puerto Rico',
    value: 'PRI',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Qatar',
    value: 'QAT',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Reunion Island',
    value: 'REU',
    zone: 1,
    dhlZone: 10,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Reunion',
    value: 'REU',
    zone: 1,
    dhlZone: 10,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Romania',
    value: 'ROU',
    zone: 'EU',
    dhlZone: 2,
    services: [
      'MTA',
      'MTC',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Russian Federation',
    value: 'RUS',
    zone: 'EU',
    dhlZone: 7,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Russia',
    value: 'RUS',
    zone: 'EU',
    dhlZone: 7,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Rwanda',
    value: 'RWA',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'San Marino',
    value: 'SMR',
    zone: 'EU',
    dhlZone: 7,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Sao Tome & Principe',
    value: 'STP',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Saudi Arabia',
    value: 'SAU',
    zone: 1,
    dhlZone: 7,
    services: [
      'MTA',
      'MTC',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Senegal',
    value: 'SEN',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Serbia',
    value: 'SRB',
    zone: 'EU',
    dhlZone: 7,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Seychelles',
    value: 'SYC',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Sierra Leone',
    value: 'SLE',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Singapore',
    value: 'SGP',
    zone: 2,
    dhlZone: 7,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Slovakia',
    value: 'SVK',
    zone: 'EU',
    dhlZone: 2,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Slovak Republic',
    value: 'SVK',
    zone: 'EU',
    dhlZone: 2,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Slovenia',
    value: 'SVN',
    zone: 'EU',
    dhlZone: 2,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Solomon Islands',
    value: 'SLB',
    zone: 2,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Somalia',
    value: 'SOM',
    zone: 1,
    dhlZone: 9,
    services: [
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'South Africa',
    value: 'ZAF',
    zone: 1,
    dhlZone: 7,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'South Korea (Republic of)',
    value: 'KOR',
    zone: 1,
    dhlZone: 7,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'South Korea',
    value: 'KOR',
    zone: 1,
    dhlZone: 7,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'South Sudan',
    value: 'SSD',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Spain',
    value: 'ESP',
    zone: 'EU',
    dhlZone: 1,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Sri Lanka',
    value: 'LKA',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'St Eustatius',
    value: 'BES',
    zone: 1,
    dhlZone: 10,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'St Helena',
    value: 'SHN',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'St Kitts & Nevis',
    value: 'KNA',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'St Lucia',
    value: 'LCA',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'St Maarten',
    value: 'SXM',
    zone: 1,
    dhlZone: 10,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'St Vincent & The Grenadines',
    value: 'VCT',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Sudan',
    value: 'SDN',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Suriname',
    value: 'SUR',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Swaziland',
    value: 'SWZ',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Sweden',
    value: 'SWE',
    zone: 'EU',
    dhlZone: 3,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Switzerland',
    value: 'CHE',
    zone: 'EU',
    dhlZone: 4,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Syria',
    value: 'SYR',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Taiwan',
    value: 'TWN',
    zone: 1,
    dhlZone: 7,
    services: [
      'MTA',
      'MTC',
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Tajikistan',
    value: 'TJK',
    zone: 'EU',
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Tanzania',
    value: 'TZA',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Thailand',
    value: 'THA',
    zone: 1,
    dhlZone: 7,
    services: [
      'MTA',
      'MTC',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Timor-Leste',
    value: 'TLS',
    zone: 1,
    dhlZone: 10,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Togo',
    value: 'TGO',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Tokelau Island',
    value: 'TKL',
    zone: 2,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Tonga',
    value: 'TON',
    zone: 2,
    dhlZone: 9,
    services: [
      'MTA',
      'MTC',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Trinidad & Tobago',
    value: 'TTO',
    zone: 1,
    dhlZone: 9,
    services: [
      'MTA',
      'MTC',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Tunisia',
    value: 'TUN',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Turkey',
    value: 'TUR',
    zone: 'EU',
    dhlZone: 7,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Turkish (Republic of Northern Cyprus)',
    value: 'CYP',
    zone: 1,
    dhlZone: 2,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Turkmenistan',
    value: 'TKM',
    zone: 'EU',
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Turks & Caicos Islands',
    value: 'TCA',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Tuvalu',
    value: 'TUV',
    zone: 2,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Uganda',
    value: 'UGA',
    zone: 1,
    dhlZone: 9,
    services: [
      'MTA',
      'MTC',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Ukraine',
    value: 'UKR',
    zone: 'EU',
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'United Arab Emirates',
    value: 'ARE',
    zone: 1,
    dhlZone: 7,
    services: [
      'MTA',
      'MTC',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'United Kingdom',
    value: 'GBR',
    zone: 'DOMESTIC',
  },
  {
    label: 'Jersey',
    value: 'GBR',
    zone: 'DOMESTIC',
  },
  {
    label: 'Guernsey',
    value: 'GBR',
    zone: 'DOMESTIC',
  },
  {
    label: 'Isle of Man',
    value: 'GBR',
    zone: 'DOMESTIC',
  },
  {
    label: 'United States',
    value: 'USA',
    zone: 3,
    dhlZone: 6,
    services: [
      'MTA',
      'MTC',
      'MP1',
      'MTI',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Uruguay',
    value: 'URY',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Uzbekistan',
    value: 'UZB',
    zone: 'EU',
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Vanuatu',
    value: 'VUT',
    zone: 1,
    dhlZone: 9,
    services: [
      'MTA',
      'MTC',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Vatican City State',
    value: 'VAT',
    zone: 'EU',
    dhlZone: 7,
    services: [
      'MP1',
      'MTI',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Venezuela',
    value: 'VEN',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Vietnam',
    value: 'VNM',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Wallis & Futuna Islands',
    value: 'WLF',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Western Sahara',
    value: 'ESH',
    zone: 1,
    dhlZone: 10,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Western Samoa',
    value: 'WSM',
    zone: 2,
    dhlZone: 10,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Yemen, Republic of',
    value: 'YEM',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Zambia',
    value: 'ZMB',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
  {
    label: 'Zimbabwe',
    value: 'ZWE',
    zone: 1,
    dhlZone: 9,
    services: [
      'MP5',
      'MTM',
      'IE1',
      'IG1',
      'GPP',
      'GPT',
    ],
  },
];
