/* eslint-disable max-len */
/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable class-methods-use-this */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/style-prop-object */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */


import React, { Component } from 'react';
import { Card, CardDeck, CardHeader, CardBody, Row, Col, Button, Media } from 'reactstrap';
import ReactTable from 'react-table';
import DeleteForeverIcon from 'mdi-react/DeleteForeverIcon';
import 'react-table/react-table.css';
import axios from 'axios';
import { connect } from 'react-redux';
// import ProductGallery from './ProductGallery';


const URL = 'http://136.144.230.112:8000';

// const getColumnWidth = (rows, accessor, headerText) => {
//   if (rows !== undefined) {
//     const maxWidth = 400;
//     let magicSpacing = 10;
//     switch (accessor) {
//       case 'total':
//         magicSpacing += 3;
//         break;
//       case 'date':
//         magicSpacing += 7;
//         break;
//       case 'status':
//         magicSpacing -= 2.5;
//         break;
//       default:
//     }
//     const cellLength = Math.max(
//       ...rows.map(row => (`${row[accessor]}` || '').length),
//       headerText.length,
//     );
//     return Math.min(maxWidth, cellLength * magicSpacing);
//   }
// };


const currencyFormat = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' });

class InventoryTable extends Component {
  constructor() {
    super();
    this.state = {
      pages: 2,
      loading: false,
      data: [],
      selected: {},
      cart: JSON.parse(localStorage.getItem('cart')) || [],
      purchases: JSON.parse(localStorage.getItem('purchases')) || {},
      // selectedItem: {},
      // images: [{ src: 'https://s3-eu-west-1.amazonaws.com/releaseimages/1/img01.jpg' },
      // { src: 'https://s3-eu-west-1.amazonaws.com/releaseimages/1/img02.jpg' }],
    };
  }

  getImageUrls() {
    const urls = [];

    if (this.state.selected.image_count) {
      for (let i = 1; i <= this.state.selected.image_count; i++) {
        if (i < 10) {
          urls[i - 1] = { src: `https://s3-eu-west-1.amazonaws.com/releaseimages/${this.state.selected.release_id.toString().split('').join('/')}/img0${i}.jpg` };
        } else {
          urls[i - 1] = { src: `https://s3-eu-west-1.amazonaws.com/releaseimages/${this.state.selected.release_id.toString().split('').join('/')}/img${i}.jpg` };
        }
      }
    } else {
      urls[0] = { src: `https://s3-eu-west-1.amazonaws.com/releaseimages/${this.state.selected.release_id.toString().split('').join('/')}/img01.jpg` };
    }
    return urls;
  }

  getDiscogsImageURL(releaseId) {
    const split = releaseId.toString().split('');
    const join = split.join('/');
    const url = `https://s3-eu-west-1.amazonaws.com/releaseimages/${join}/img01.jpg`;
    return url;
  }

  completePurchase(seller) {
    console.log('COMPLETING PURCHASE');
    console.log(seller);
    const purchase = this.state.purchases[seller];
    console.log(purchase);
    axios.post(`${URL}/admin/purchases/create`, {
      purchase,
    }, { headers: { Authorization: localStorage.getItem('mm_admin') } })
      .then((res) => {
      // Update react-table
        console.log('RESPONSE:');
        console.log(res);
        if (res.data.purchase.purchase_id !== null) {
          const currentState = this.state.purchases;
          currentState[seller].isComplete = true;
          currentState[seller].purchaseId = res.data.purchase.purchase_id;
          this.setState({ purchases: currentState });
          localStorage.setItem('purchases', JSON.stringify(currentState));
          // currentState[seller].items.map(item => this.addItemToCart(item));
        }
      });
  }

  updatePurchaseDetails(event, seller) {
    const purchases = this.state.purchases;
    purchases[seller][event.target.name] = event.target.value;
    this.setState({ purchases });
  }

  addItemToCart(item) {
    const purchases = this.state.purchases;
    if (purchases[item.seller_username] === undefined || purchases[item.seller_username].isComplete === true) {
      purchases[item.seller_username] = {
        items: [item], seller_id: item.seller_id, instructions: '', shipping_address: '', isComplete: false, purchaseId: null,
      };
      this.setState({
        purchases,
      }, () => {
        localStorage.setItem('purchases', JSON.stringify(purchases));
      });
    } else {
      if (!purchases[item.seller_username].items.some(cartItem => cartItem.listing_id === item.listing_id)) {
        purchases[item.seller_username].items.push(item);
      } else {
        purchases[item.seller_username].items = purchases[item.seller_username].items.filter(cartItem => cartItem.listing_id !== item.listing_id);
        if (purchases[item.seller_username].items.length < 1) {
          delete purchases[item.seller_username];
        }
      }
      this.setState({
        purchases,
      }, () => {
        localStorage.setItem('purchases', JSON.stringify(purchases));
      });
    }
  }

  // groupBy(data, keys) {
  //   data.reduce((storage, item) => {
  //   // returns an object containing keys and values of each item
  //     const groupValues = keys.reduce((values, key) => {
  //       values[key] = item[key];
  //       return values;
  //     }, {});
  //
  //     const group = Object.values(groupValues).join(' ');
  //     storage[group] = storage[group] || [];
  //
  //     if (keys.every(key => item[key] === groupValues[key])) {
  //       storage[group].push(item);
  //     }
  //     return storage;
  //   }, {});
  // }

  groupBy(data, key) { // `data` is an array of objects, `key` is the key (or property accessor) to group by
  // reduce runs this anonymous function on each element of `data` (the `item` parameter,
  // returning the `storage` parameter at the end
    return data.reduce((storage, item) => {
    // get the first instance of the key by which we're grouping
      const group = item[key];

      // set `storage` for this instance of group to the outer scope (if not empty) or initialize it
      storage[group] = storage[group] || [];

      // add this item to its group within `storage`
      storage[group].push(item);

      // return the updated storage to the reduce function, which will then loop through the next
      return storage;
    }, {}); // {} is the initial value of the storage
  }


  render() {
    const inventoryColumns = [
      {
        Header: 'Seller',
        id: 'seller_username',
        accessor: 'seller_username',
      },
      {
        Header: 'Listing ID',
        id: 'listing_id',
        accessor: 'listing_id',
      }, {
        Header: 'Description',
        id: 'description',
        accessor: 'description',
      }, {
        Header: 'Media',
        id: 'media_cond',
        accessor: 'media_cond',
      }, {
        Header: 'Sleeve',
        id: 'sleeve_cond',
        accessor: 'sleeve_cond',
      }, {
        Header: 'Price',
        id: 'discogs_price',
        accessor: 'discogs_price',
        Cell: row => (
          <div>
            <span>{currencyFormat.format(row.original.discogs_price)}</span>
          </div>
        ),
      }, {
        Header: 'Status',
        id: 'status',
        accessor: 'status',
        Filter: ({ filter, onChange }) =>
          (<select
            onChange={event => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'all'}
          >
            <option value="">All</option>
            <option value="for sale">For Sale</option>
            <option value="sold">Sold</option>
            <option value="draft">Draft</option>
            <option value="violation">Violation</option>
          </select>),
      },
    ];

    return (
      <div>
        <Col>
          <Card
            style={{
            height: '500px', // This will force the table body to overflow and scroll, since there is not enough room
            }}
          >
            <CardBody>
              {/* <ReactTable
                  data={this.props.orders}
                  columns={ordersColumns}
                  showPagination= {false}
                  className="-striped -highlight -responsive"
                  getTrProps={(state, rowInfo) => {
                    if (rowInfo != undefined) {
                      return {
                        onClick: (e) => {
                          this.setState({
                            selected: rowInfo.original
                          })
                        },
                        style: {
                          background: rowInfo.original === this.state.selected ? '#00afec' : 'white',
                          color: rowInfo.original === this.state.selected ? 'white' : 'black'
                        }
                      }
                    } else {
                      return {}
                    }
                  }}
                  style={{
                  height: "400px" // This will force the table body to overflow and scroll, since there is not enough room
                  }}
                  /> */}
              <ReactTable
                style={{
                    height: '400px', // This will force the table body to overflow and scroll, since there is not enough room
                    }}
                data={this.state.data} // should default to []
                columns={inventoryColumns}
                pages={this.state.pages} // should default to -1 (which means we don't know how many pages we have)
                loading={this.state.loading}
                manual
                filterable
                multiSort={false}
                defaultPageSize={50}
                defaultSorted={[
                      {
                        id: 'listing_id',
                        desc: true,
                      },
                    ]}
                className="-striped -highlight -responsive"
                getTrProps={(state, rowInfo) => {
                      if (rowInfo !== undefined) {
                        return {
                          onClick: () => {
                            this.setState({
                              selected: rowInfo.original,
                            });
                          },
                          style: {
                            background: rowInfo.original === this.state.selected ? '#00afec' : 'white',
                            color: rowInfo.original === this.state.selected ? 'white' : 'black',
                          },
                        };
                      }
                        return {};
                    }} // informs React Table that you'll be handling sorting and pagination server-side
                onFetchData={(state) => {
                      // show the loading overlay
                      this.setState({ loading: true });
                      axios.post(`${URL}/admin/inventory/used_stock`, {
                        page: state.page,
                        pageSize: state.pageSize,
                        sorted: state.sorted,
                        filtered: state.filtered,
                      }, { headers: { Authorization: localStorage.getItem('mm_admin') } })
                      .then((res) => {
                        // Update react-table
                        this.setState({
                          data: res.data.items,
                          pages: res.data.pages,
                          loading: false,
                        });
                      });
                    }}
              />
              {/* <Button color="primary" onClick={() => this.handleButtonClick()}>primary</Button> */}
            </CardBody>
          </Card>
          <CardDeck>
            <Card>
              <CardBody>
                <center><h4 className="bold-text">{this.state.selected.description}</h4></center>
                <br />
                <div>
                  <center><b>{this.state.selected.comments}</b></center>
                </div>
                <br />
                <div className="project-summary">
                  <table className="project-summary__info">
                    <tbody>
                      <tr>
                        <th>Listed date:</th>
                        <td>{new Date(this.state.selected.date_listed).toLocaleDateString()}</td>
                      </tr>
                      <tr>
                        <th>Discogs Price:</th>
                        <td>{this.state.selected.discogs_price}</td>
                      </tr>
                      <tr>
                        <th>Status:</th>
                        <td>{this.state.selected.status}</td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <th>Media:</th>
                        <td>{this.state.selected.media_cond}</td>
                      </tr>
                      <tr>
                        <th>Sleeve:</th>
                        <td>{this.state.selected.sleeve_cond}</td>
                      </tr>
                      <tr>
                        <th>Location:</th>
                        <td>{this.state.selected.location}</td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <th>Private Comments:</th>
                        <td>{this.state.selected.external_id}</td>
                      </tr>
                      <tr>
                        <th>Release ID:</th>
                        <td>{this.state.selected.release_id}</td>
                      </tr>
                      <tr>
                        <Button
                          color={this.state.cart.filter(cartItem => cartItem.listing_id === this.state.selected.listing_id).length === 0 ? (
                            'primary'
                          ) : 'secondary'}
                          size="sm"
                          disabled={this.state.selected.status !== 'For Sale'}
                          onClick={() => this.addItemToCart(this.state.selected)}
                        >{this.state.purchases[this.state.selected.seller_username] === undefined ? (
                          'Add to Cart'
                        ) : this.state.purchases[this.state.selected.seller_username].items.filter(cartItem => cartItem.listing_id === this.state.selected.listing_id).length === 0 ? 'Add to Cart' : 'Remove from Cart' }
                        </Button>
                      </tr>
                    </tbody>
                  </table>
                  <hr />
                </div>
              </CardBody>
            </Card>
          </CardDeck>
        </Col>
        <Col md={12}>
          { Object.keys(this.state.purchases).map(seller =>
            (
              <div>
                <Row>
                  <Card>
                    <CardHeader>
                      <h5 className="bold-text">Order from <b>{seller}</b></h5>
                    </CardHeader>
                    { this.state.purchases[seller].isComplete === false ?
                      <CardBody>
                        <div className="project-summary">
                          <div className="project-summary__stats">
                            <div className="project-summary__stat">
                              <table className="project-summary__info" width="100%">
                                <tbody>
                                  {this.state.purchases[seller].items.map(item =>
                                    (<div>
                                      <tr>
                                        <td>
                                          <Button flat className="cart__table-btn" onClick={() => this.addItemToCart(item)}>
                                            <DeleteForeverIcon />
                                          </Button>
                                        </td>
                                        <td>
                                          <Media top href={`https://www.discogs.com/sell/item/${item.listing_id}`} target="_blank">
                                            <Media
                                              object
                                              src={this.getDiscogsImageURL(item.release_id)}
                                              alt="Generic placeholder image"
                                              style={{
                                                height: '100%',
                                                width: '100%',
                                                maxHeight: '50px',
                                                maxWidth: '50px',
                                              }}
                                            />
                                          </Media>
                                        </td>
                                        <td>{item.description}<br />{`Media: ${item.media_cond} / Sleeve: ${item.sleeve_cond}`}<br /><b>{currencyFormat.format(item.discogs_price)}</b></td>
                                      </tr>
                                      <br />
                                    </div>))}
                                </tbody>
                              </table>
                            </div>
                            <div className="project-summary__stat">
                              <table className="project-summary__info">
                                <tbody>
                                  <tr>
                                    <div className="form__form-group">
                                      <label className="form__form-group-label">Instructions (Optional)</label>
                                      <div className="form__form-group-field">
                                        <textarea
                                          name="instructions"
                                          rows="5"
                                          cols="50"
                                          value={this.state.purchases[seller].instructions}
                                          onChange={event => this.updatePurchaseDetails(event, seller)}
                                        />
                                      </div>
                                      <label className="form__form-group-label">Shipping Address</label>
                                      <div className="form__form-group-field">
                                        <textarea
                                          name="shipping_address"
                                          rows="5"
                                          cols="50"
                                          value={this.state.purchases[seller].shipping_address}
                                          onChange={event => this.updatePurchaseDetails(event, seller)}
                                        />
                                      </div>
                                    </div>
                                  </tr>
                                  <tr>
                                    <th>Total:</th>
                                    <td>{currencyFormat.format(this.state.purchases[seller].items.reduce((a, b) => +a + +b.discogs_price, 0))}</td>
                                  </tr>
                                  <tr>
                                    <Button
                                      color="primary"
                                      size="sm"
                                      // disabled={this.state.cart.includes(this.state.selected)}
                                      onClick={() => this.completePurchase(seller)}
                                    >Complete Purchase
                                    </Button>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <hr />
                        </div>
                      </CardBody>
                    : <CardBody>
                      <h5><b>{seller}</b></h5>
                      {this.state.purchases[seller].items.map(item => (
                        <div>
                          <h5>https://www.discogs.com/release/{item.release_id}</h5>
                          <h5>{item.description}</h5>
                          <h5>Condition: {item.media_cond}/{item.sleeve_cond}</h5>
                          <h5 className="bold-text">Location: {item.location !== 'null' ? item.location : 'No Location'}</h5>
                          <br />
                        </div>
                      ))}
                      <h4 className="bold-text">Purchase ID: {this.state.purchases[seller].purchaseId}</h4>
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => {
                          const inp = document.createElement('textarea');
                          document.body.appendChild(inp);
                          inp.value = this.state.purchases[seller].purchaseId;
                          inp.select();
                          document.execCommand('copy');
                          inp.remove();
                        }}
                      >Copy ID
                      </Button>
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => {
                          let textToCopy = '';
                          this.state.purchases[seller].items.map((item) => {
                            textToCopy += `${seller}\n`;
                            textToCopy += `https://www.discogs.com/release/${item.release_id}\n`;
                            textToCopy += `${item.description}\n`;
                            textToCopy += `Condition: ${item.media_cond}/${item.sleeve_cond}\n`;
                            textToCopy += `Location: ${item.location !== 'null' ? item.location : 'No Location'}\n\n`;
                          });
                          const inp = document.createElement('textarea');
                          document.body.appendChild(inp);
                          inp.value = textToCopy;
                          inp.select();
                          document.execCommand('copy');
                          inp.remove();
                        }}
                      >Copy Items
                      </Button>
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => {
                          const currentState = this.state.purchases;
                          delete currentState[seller];
                          this.setState({ purchases: currentState });
                          localStorage.setItem('purchases', JSON.stringify(currentState));
                        }}
                      >Close
                      </Button>
                    </CardBody> }
                  </Card>
                </Row>
              </div>
            ))
          }
        </Col>
      </div>

    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
});

// export default connect()(SidebarContent);
export default connect(mapStateToProps)(InventoryTable);
