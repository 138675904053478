import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import DiscogsOrdersTable from './components/DiscogsOrdersTable';
// import NewItemsTable from './components/NewItemsTable';

const DiscogsOrders = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h5 className="page-title">Orders / Discogs</h5>
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <DiscogsOrdersTable />
      </Col>
    </Row>
  </Container>
);

export default DiscogsOrders;
