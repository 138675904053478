import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import OpenOrdersTable from './components/OpenOrdersTable2';

const BandCampOrders = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h5 className="page-title">AboveBoard / Orders / BandCamp (Domestic Orders)</h5>
      </Col>
    </Row>
    <Row>
      <OpenOrdersTable />
    </Row>
  </Container>
);

export default BandCampOrders;
