import { AUTHENTICATED, UNAUTHENTICATED, AUTHENTICATION_ERROR, SET_CURRENT_USER } from '../actions/authActions';

export default function (state = {}, action) {
  switch (action.type) {
    case AUTHENTICATED:
      return { ...state, authenticated: true };
    case UNAUTHENTICATED:
      return { ...state, authenticated: false };
    case AUTHENTICATION_ERROR:
      return { ...state, error: action.payload };
    case SET_CURRENT_USER:
      console.log(action.user);
      return { ...state, user: action.user };
    default:
      return state;
  }
}
