import React from 'react';
import { Container, Col, Row } from 'reactstrap';
// import PurchasesTable from './components/PurchasesTable';
import InventoryTable from './components/InventoryTable';

// import NewItemsTable from './components/NewItemsTable';

const Purchases = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h5 className="page-title">Create Purchase Orders</h5>
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <InventoryTable />
      </Col>
    </Row>
  </Container>
);

export default Purchases;
