/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/jsx-curly-brace-presence */


import React, { Component } from 'react';
import { Card, CardDeck, CardBody, Col, Button } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import axios from 'axios';
import { connect } from 'react-redux';

const currencyFormatGbp = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' });

const isEquals = require('lodash.isequal');

const URL = 'http://136.144.230.112:8000';

const getColumnWidth = (rows, accessor, headerText) => {
  if (rows !== undefined) {
    const maxWidth = 400;
    let magicSpacing = 10;
    switch (accessor) {
      case 'total':
        magicSpacing += 3;
        break;
      case 'seller_id':
        magicSpacing += 10;
        break;
      case 'status':
        magicSpacing += 10;
        break;
      default:
    }
    const cellLength = Math.max(
      ...rows.map(row => (`${row[accessor]}` || '').length),
      headerText.length,
    );
    return Math.min(maxWidth, cellLength * magicSpacing);
  }
  return null;
};

// const currencyFormat = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' })

class MerchItemsTable extends Component {
  constructor() {
    super();
    this.state = {
      pages: 2,
      loading: false,
      data: [],
      selected: undefined,
      selectedOriginal: undefined,
      sellers: [],
      isEditing: false,
      isAdding: false,
    };
    this.handleItemChange = this.handleItemChange.bind(this);
  }

  async componentWillMount() {
    // const response = await axios.get(
    //   `${URL}/admin/fulfillment/sellers`,
    //   { headers: { Authorization: localStorage.getItem('mm_admin') } },
    // );
    // this.setState({
    //   sellers: response.data.sellers,
    // });
  }

  getSellerName(id) {
    const retSeller = this.state.sellers.find(seller => seller.id === id).name;
    if (retSeller) {
      return retSeller.name;
    }
    return null;
  }

  handleItemChange(e) {
    const selected = { ...this.state.selected };
    selected[e.target.name] = e.target.value;
    this.setState({ selected });
  }

  async saveItem() {
    await axios.post(`${URL}/admin/aboveboard/merch/update`, {
      item: this.state.selected,
    }, { headers: { Authorization: localStorage.getItem('mm_admin') } })
      .then((res) => {
      // Update react-table
        // const newData = [...this.state.data];
        // res.data.orders.map((id) => {
        //   const index = newData.findIndex(obj => obj.id === id.id);
        //   if (index !== -1) {
        //     newData.splice(index, 1);
        //   }
        // });
        const dataCopy = this.state.data;
        dataCopy[dataCopy.indexOf(dataCopy.find(obj => obj.package_id === res.data.item.package_id))] = res.data.item;

        this.setState({
          selectedOriginal: res.data.item,
          isEditing: false,
          data: dataCopy,
        });
      });
    return true;
  }

  render() {
    const itemsColumns = [
      // {
      //   Header: 'Seller',
      //   id: 'seller_id',
      //   accessor: d => this.state.sellers.find(seller => seller.id === d.seller_id).name,
      //   width: getColumnWidth(this.state.data, 'seller_id', 'Order Id'),
      //   Filter: ({ filter, onChange }) =>
      //     (
      //       <select
      //         onChange={event => onChange(event.target.value)}
      //         style={{ width: '100%' }}
      //         value={filter ? filter.value : 'all'}
      //       >
      //         <option value="">All Sellers</option>
      //         {this.state.sellers.map(seller => <option value={seller.id}>{seller.name}</option>)}
      //       </select>),
      // },
      {
        Header: 'SKU',
        id: 'sku',
        accessor: 'sku',
        width: getColumnWidth(this.state.data, 'sku', 'SKU'),
      }, {
        Header: 'Label',
        id: 'subdomain',
        accessor: 'subdomain',
        width: getColumnWidth(this.state.data, 'subdomain', 'Label'),
      }, {
        Header: 'Title',
        id: 'album_title',
        accessor: 'album_title',
        width: getColumnWidth(this.state.data, 'album_title', 'Title'),
      }, {
        Header: 'Format',
        id: 'title',
        accessor: 'title',
        width: getColumnWidth(this.state.data, 'title', 'Format'),
      }, {
        Header: 'Price',
        id: 'price',
        accessor: 'price',
        width: getColumnWidth(this.state.data, 'price', 'price'),
      }, {
        Header: 'QTY',
        id: 'mm_quantity',
        accessor: 'mm_quantity',
        width: getColumnWidth(this.state.data, 'mm_quantity', 'QTY'),
      },
    ];

    return (
      <Col>
        <Card
          style={{
            height: '550px',
          }}
        >
          <CardBody>
            <Button
              color={this.state.isAdding ? ('danger') : 'primary'}
              size="sm"
              onClick={() => this.setState(prevState => ({ isAdding: !prevState.isAdding }))}
            >{this.state.isAdding ? ('Cancel') : 'Add New Item'}
            </Button>
            <div>
              <ReactTable
                style={{
                    height: '400px',
                  }}
                data={this.state.data} // should default to []
                columns={itemsColumns}
                pages={this.state.pages}
                loading={this.state.loading}
                manual
                filterable
                multiSort={false}
                defaultPageSize={50}
                // defaultSorted={[
                //     {
                //       id: 'date_added',
                //       desc: true,
                //     },
                //   ]}
                className="-striped -highlight -responsive"
                getTrProps={(state, rowInfo) => {
                    if (rowInfo !== undefined) {
                      return {
                        onClick: () => {
                          this.setState({
                            selected: rowInfo.original,
                            selectedOriginal: rowInfo.original,
                            isEditing: false,
                          });
                        },
                        style: {
                          background: rowInfo.original === this.state.selected ? '#00afec' : 'white',
                          color: rowInfo.original === this.state.selected ? 'white' : 'black',
                        },
                      };
                    }
                    return {};
                  }} // informs React Table that you'll be handling sorting and pagination server-side
                onFetchData={(state) => {
                    // show the loading overlay
                    this.setState({ loading: true });
                    axios.post(`${URL}/admin/aboveboard/merch`, {
                      page: state.page,
                      pageSize: state.pageSize,
                      sorted: state.sorted,
                      filtered: state.filtered,
                    }, { headers: { Authorization: localStorage.getItem('mm_admin') } })
                    .then((res) => {
                      // Update react-table
                      this.setState({
                        data: res.data.items,
                        pages: res.data.pages,
                        loading: false,
                      });
                    });
                  }}
              />
            </div>
          </CardBody>
        </Card>
        {this.state.selected !== undefined && !this.state.isAdding ? (
          <CardDeck>
            <Card>
              <CardBody>
                <Button
                  color="primary"
                  size="sm"
                  disabled={this.state.isEditing}
                  onClick={() => this.setState(prevState => ({ isEditing: !prevState.isEditing }))}
                >Edit
                </Button>
                {this.state.isEditing ? (
                  <Button
                    color="danger"
                    size="sm"
                    onClick={() =>
                        this.setState(prevState => ({
                          isEditing: !prevState.isEditing,
                          selected: prevState.selectedOriginal,
                        }))
                      }
                  >Cancel
                  </Button>
                  ) : null }
                <div className="project-summary">
                  <div className="project-summary__stats">
                    <div className="project-summary__stat">
                      <table className="project-summary__info">
                        <tbody>
                          <tr>
                            <th>SKU:</th>
                            <td>
                              {this.state.isEditing ? (
                                <input
                                  type="text"
                                  name="sku"
                                  value={this.state.selected.sku}
                                  onChange={this.handleItemChange}
                                />
                              ) : <b>{this.state.selected.sku}</b> }
                            </td>
                          </tr>
                          <tr>
                            <th>Format/Title:</th>
                            <td>{this.state.selected.title}</td>
                          </tr>
                          <tr>
                            <th>Title:</th>
                            <td>{this.state.selected.album_title}</td>
                          </tr>
                          <tr>
                            <th>Qty:</th>
                            <td>
                              {this.state.isEditing ? (
                                <input
                                  type="number"
                                  name="mm_quantity"
                                  value={this.state.selected.mm_quantity}
                                  onChange={this.handleItemChange}
                                />
                              ) : <b>{this.state.selected.mm_quantity}</b> }
                            </td>
                          </tr>
                          <tr>
                            <th>Weight (g):</th>
                            <td>
                              {this.state.isEditing ? (
                                <input
                                  type="number"
                                  name="mm_weight"
                                  value={this.state.selected.mm_weight}
                                  onChange={this.handleItemChange}
                                />
                              ) : this.state.selected.mm_weight }
                            </td>
                          </tr>
                          <tr>
                            <th>Location:</th>
                            <td>
                              {this.state.isEditing ? (
                                <input
                                  type="text"
                                  name="mm_location"
                                  value={this.state.selected.mm_location}
                                  onChange={this.handleItemChange}
                                />
                              ) : <b>{this.state.selected.mm_location}</b> }
                            </td>
                          </tr>
                          <tr>
                            <th>Price:</th>
                            <td>{currencyFormatGbp.format(this.state.selected.price)}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="project-summary__stat">
                      <table className="project-summary__info">
                        <tbody>
                          <tr>
                            <th>Package Size:</th>
                            <td>
                              <select
                                onChange={event => this.handleItemChange(event)}
                                value={this.state.selected.package_size}
                                name="package_size"
                                disabled={!this.state.isEditing}
                              >
                                <option value={undefined} />
                                <option value="LL">Large Letter</option>
                                <option value="SP">Small Parcel</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <th>Size Type:</th>
                            <td>
                              <select
                                onChange={event => this.handleItemChange(event)}
                                value={this.state.selected.mm_size_type}
                                name="mm_size_type"
                                disabled={!this.state.isEditing}
                              >
                                <option value={undefined} />
                                <option value="Large Single">{'12" x 1'}</option>
                                <option value="Small Single">{'7"/CD x 1'}</option>
                                <option value="Large Double">{'12" x 2+'}</option>
                                <option value="Small Double">{'7"/CD x 2+'}</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <th>Format QTY:</th>
                            <td>
                              {this.state.isEditing ? (
                                <input
                                  type="number"
                                  name="mm_format_qty"
                                  value={this.state.selected.mm_format_qty}
                                  onChange={this.handleItemChange}
                                />
                              ) : this.state.selected.mm_format_qty }
                            </td>
                          </tr>
                          <tr>
                            <th>Fulfilment Location:</th>
                            <td>
                              <select
                                onChange={event => this.handleItemChange(event)}
                                value={this.state.selected.mm_stock_location}
                                name="mm_stock_location"
                                disabled={!this.state.isEditing}
                              >
                                <option value={undefined} />
                                <option value="1">Orpington</option>
                                <option value="2">Gosport</option>
                              </select>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <hr />
                </div>
                { isEquals(this.state.selectedOriginal, this.state.selected) ? (
                    null
                  ) :
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => this.saveItem()}
                    >Save Changes
                    </Button> }
              </CardBody>
            </Card>
          </CardDeck>) : null }
      </Col>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
});

// export default connect()(SidebarContent);
export default connect(mapStateToProps)(MerchItemsTable);
