/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable arrow-body-style */
/* eslint-disable no-prototype-builtins */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */


import React, { Component } from 'react';
import { Button } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import axios from 'axios';
import { connect } from 'react-redux';
import ReleaseSummary from './ReleaseSummary';

const URL = 'http://136.144.230.112:8000';

const sortReleaseArtists = (artists) => {
  let sortedString = '';
  let last = {};

  artists.map((artist) => {
    if (artist.join_relation != null) {
      sortedString = `${sortedString + artist.name} ${artist.join_relation} `;
    } else {
      last = artist;
    }
  });
  sortedString = `${sortedString + last.name}`;
  return sortedString;
};

const getFormatsFormatted = (formats) => {
  // let sortedString = '';
  const formatsArray = [];

  formats.map((format) => {
    let formatString = format.qty > 1 ? `${format.qty}x ${format.format_name}` : `${format.format_name}`;
    formatString = `${formatString}, ${format.descriptions ? format.descriptions.join(', ') : format.descriptions}
    ${format.format_text ? `, ${format.format_text}` : ''}`;
    formatsArray.push(formatString);
  });
  return formatsArray;
};

// const getColumnWidth = (rows, accessor, headerText) => {
//   if (rows !== undefined) {
//     const maxWidth = 400;
//     let magicSpacing = 10;
//     switch (accessor) {
//       case 'total':
//         magicSpacing += 3;
//         break;
//       case 'seller_id':
//         magicSpacing += 10;
//         break;
//       case 'status':
//         magicSpacing += 10;
//         break;
//       default:
//     }
//     const cellLength = Math.max(
//       ...rows.map(row => (`${row[accessor]}` || '').length),
//       headerText.length,
//     );
//     return Math.min(maxWidth, cellLength * magicSpacing);
//   }
//   return null;
// };

// const currencyFormat = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' })

class AddNewItemEditor extends Component {
  constructor() {
    super();
    this.state = {
      seller: undefined,
      format: undefined,
      size: undefined,
      newItem: {
        sku: undefined,
        qty: undefined,
        date_added: new Date().toLocaleDateString(),
        artist: undefined,
        title: undefined,
        min_price_gbp: undefined,
        min_price_eur: undefined,
        current_price_gbp: undefined,
        current_price_eur: undefined,
        asin: undefined,
        ean: undefined,
        release_id: undefined,
        location: undefined,
      },
      sellers: ['', 'MM', 'SIP'],
      formats: ['', 'CD', 'Vinyl', 'DVD', 'Blu-Ray'],
      sizes: ['', 'LL', 'SP'],
      releases: [],
      discogsReleases: {},
    };
    this.handleItemChange = this.handleItemChange.bind(this);
    this.validate = this.validate.bind(this);
  }

  async componentWillMount() {
    // const response = await axios.get(
    //   `${URL}/admin/fulfillment/sellers`,
    //   { headers: { Authorization: localStorage.getItem('mm_admin') } },
    // );
    // this.setState({
    //   sellers: response.data.sellers,
    // });
  }

  async getDiscogsReleasesByBarcode(ean) {
    if (ean.length > 10) {
      await axios.post(`${URL}/admin/inventory/new_stock/barcode`, {
        ean,
      }, { headers: { Authorization: localStorage.getItem('mm_admin') } })
        .then((res) => {
          this.setState({ releases: res.data.releases });
        });
    }
  }

  async getReleaseFromDiscogs(id) {
    const res = await axios.get(`https://api.discogs.com/releases/${id}?gbp`);
    const releases = this.state.discogsReleases;
    releases[id] = res.data;
    this.setState({ discogsReleases: releases });
  }

  async matchItem(row) {
    const newItem = this.state.newItem;
    newItem.release_id = row.original.id;
    newItem.artist = sortReleaseArtists(row.original.artists);
    newItem.title = row.original.title;
    row.original.identifiers.map((identifier) => {
      if (identifier.type.includes('ASIN') || identifier.type.includes('asin')) {
        newItem.asin = identifier.value;
      }
    });
    this.setState({ newItem });
  }

  async saveItem() {
    if (this.validate()) {
      await axios.post(`${URL}/admin/inventory/new_stock/add`, {
        item: this.state.newItem,
        seller: this.state.seller,
        format: this.state.format,
        size: this.state.size,
      }, { headers: { Authorization: localStorage.getItem('mm_admin') } })
        .then((res) => {
          console.log(res.data);
          this.setState({
            newItem: {
              sku: undefined,
              quantity: undefined,
              date_added: new Date().toLocaleDateString(),
              artist: undefined,
              title: undefined,
              min_price_gbp: undefined,
              min_price_eur: undefined,
              current_price_gbp: undefined,
              current_price_eur: undefined,
              asin: undefined,
              ean: undefined,
              release_id: undefined,
            },
          });
          this.props.finished();
        });
    } else {
      console.log('Validation Failed!');
    }
  }

  handleItemChange(e) {
    const newItem = { ...this.state.newItem };
    newItem[e.target.name] = e.target.value;
    this.setState({ newItem });
    if (e.target.name === 'ean') {
      this.getDiscogsReleasesByBarcode(e.target.value);
    }
  }

  validate() {
    let validated = true;
    if (this.state.newItem.artist === undefined) {
      validated = false;
    }
    if (this.state.newItem.title === undefined) {
      validated = false;
    }
    if (this.state.newItem.qty === undefined) {
      validated = false;
    }
    if (this.state.newItem.min_price_gbp === undefined) {
      validated = false;
    }
    if (this.state.newItem.location === undefined) {
      validated = false;
    }
    if (this.state.seller === undefined) {
      validated = false;
    }
    if (this.state.format === undefined) {
      validated = false;
    }
    if (this.state.size === undefined) {
      validated = false;
    }
    return validated;
  }

  render() {
    const releaseColumns = [
      {
        Header: 'Artist',
        id: 'artist',
        // width: getColumnWidth(this.state.data, 'artist', 'Artist'),
        Cell: row => (
          <div>
            <tr>
              {sortReleaseArtists(row.original.artists)}
            </tr>
          </div>
        ),
      }, {
        Header: 'Title',
        id: 'title',
        accessor: 'title',
        // width: getColumnWidth(this.state.data, 'title', 'Title'),
      }, {
        Header: 'Format',
        id: 'format',
        // width: getColumnWidth(this.state.data, 'artist', 'Artist'),
        Cell: row => (
          <div>
            {getFormatsFormatted(row.original.formats).map(i =>
              <tr>{i}</tr>)}
          </div>
        ),
      }, {
        Header: 'Released',
        id: 'released',
        accessor: 'released',
        // width: getColumnWidth(this.state.data, 'title', 'Title'),
      }, {
        Header: 'Country',
        id: 'country',
        accessor: 'country',
        // width: getColumnWidth(this.state.data, 'title', 'Title'),
      }, {
        Header: 'Match',
        id: 'match',
        Cell: row => (
          <div>
            <Button
              color="secondary"
              size="sm"
              onClick={() => this.matchItem(row)}
            >Match
            </Button>
          </div>
        ),
      },
      // {
      //   expander: true,
      //   Header: () => <strong>More</strong>,
      //   width: 65,
      //   Expander: ({ isExpanded }) =>
      //     (
      //       <div>
      //         {isExpanded
      //                   ? <span>&#x2299;</span>
      //                   : <span>&#x2295;</span>}
      //       </div>),
      //   style: {
      //     cursor: 'pointer',
      //     fontSize: 25,
      //     padding: '0',
      //     textAlign: 'center',
      //     userSelect: 'none',
      //   },
      //   Footer: () => <span>&hearts;</span>,
      // },
      // {
      //   Header: 'Format',
      //   id: 'artist',
      //   accessor: 'artist',
      //   width: getColumnWidth(this.state.data, 'artist', 'Artist'),
      // }, {
      //   Header: 'Title',
      //   id: 'title',
      //   accessor: 'title',
      // }, {
      //   Header: 'Format',
      //   id: 'format',
      //   accessor: 'format',
      //   width: getColumnWidth(this.state.data, 'format', 'Format'),
      //   // Filter: ({ filter, onChange }) =>
      //   //             <IntervalDatePickerField
      //   //               onChange={event => onChange(event.target.value)}
      //   //                 style={{ width: "100%" }}
      //   //                 value={filter ? filter.value : "all"}
      //   //             />,
      // },
    ];
    return (
      <div className="project-summary">
        <div className="card__title">
          {/* <h5 className="bold-text">
            {`${this.state.selected.sku} (${this.state.selected.artist} - ${this.state.selected.title})`}
          </h5> */}
        </div>
        <div className="project-summary__stats">
          <div className="project-summary__stat">
            <table className="project-summary__info">
              <tbody>
                <tr>
                  <th>Seller (Req):</th>
                  <td>
                    <select
                      onChange={event => this.setState({ seller: event.target.value })}
                      style={{ width: '100%' }}
                    >
                      {this.state.sellers.map(seller => <option value={seller}>{seller}</option>)}
                    </select>
                  </td>
                </tr>
                <tr>
                  <th>Artist (Req):</th>
                  <td>
                    <input
                      type="text"
                      name="artist"
                      value={this.state.newItem.artist}
                      onChange={this.handleItemChange}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Title (Req):</th>
                  <td>
                    <input
                      type="text"
                      name="title"
                      value={this.state.newItem.title}
                      onChange={this.handleItemChange}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Qty (Req):</th>
                  <td>
                    <input
                      type="number"
                      name="qty"
                      value={this.state.newItem.qty}
                      onChange={this.handleItemChange}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="project-summary__stat">
            <table className="project-summary__info">
              <tbody>
                <tr>
                  <th>Min Price GBP (Req):</th>
                  <td>
                    <input
                      type="number"
                      name="min_price_gbp"
                      value={this.state.newItem.min_price_gbp}
                      onChange={this.handleItemChange}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Current Price GBP:</th>
                  <td>
                    <input
                      type="number"
                      name="current_price_gbp"
                      value={this.state.newItem.current_price_gbp}
                      onChange={this.handleItemChange}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Min Price EUR:</th>
                  <td>
                    <input
                      type="number"
                      name="min_price_eur"
                      value={this.state.newItem.min_price_eur}
                      onChange={this.handleItemChange}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Current Price EUR:</th>
                  <td>
                    <input
                      type="number"
                      name="current_price_eur"
                      value={this.state.newItem.current_price_eur}
                      onChange={this.handleItemChange}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="project-summary__stat">
            <table className="project-summary__info">
              <tbody>
                <tr>
                  <th>Format (Req):</th>
                  <td>
                    <select
                      onChange={event => this.setState({ format: event.target.value })}
                      style={{ width: '100%' }}
                    >
                      {this.state.formats.map(format => <option value={format}>{format}</option>)}
                    </select>
                  </td>
                </tr>
                <tr>
                  <th>Size (Req):</th>
                  <td>
                    <select
                      onChange={event => this.setState({ size: event.target.value })}
                      style={{ width: '100%' }}
                    >
                      {this.state.sizes.map(size => <option value={size}>{size}</option>)}
                    </select>
                  </td>
                </tr>
                <tr>
                  <th>ASIN:</th>
                  <td>
                    <input
                      type="text"
                      name="asin"
                      value={this.state.newItem.asin}
                      onChange={this.handleItemChange}
                    />
                  </td>
                </tr>
                <tr>
                  <th>EAN:</th>
                  <td>
                    <input
                      type="number"
                      name="ean"
                      value={this.state.newItem.ean}
                      onChange={this.handleItemChange}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Release ID:</th>
                  <td>
                    <input
                      type="text"
                      name="release_id"
                      value={this.state.newItem.release_id}
                      onChange={this.handleItemChange}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Location (Req):</th>
                  <td>
                    <input
                      type="text"
                      name="location"
                      value={this.state.newItem.location}
                      onChange={this.handleItemChange}
                    />
                  </td>
                </tr>
                {/* <Button
                    color="primary"
                    size="sm"
                    onClick={() => this.copyShippingAddressToClipboard()}
                    >Copy Address
                  </Button> */}
              </tbody>
            </table>
          </div>
        </div>
        <hr />
        <Button
          color="primary"
          size="sm"
          onClick={() => this.saveItem()}
        >Save Item
        </Button>
        {this.state.releases.length < 1 ? (
              null)
              : (
                <div>
                  <ReactTable
                    data={this.state.releases} // should default to []
                    columns={releaseColumns}
                    // pages={this.state.pages}
                    loading={this.state.loading}
                    multiSort={false}
                    // defaultPageSize={50}
                    className="-striped -highlight -responsive"
                    showPagination={false}
                    sortable={false}
                    // pivotBy
                    // onExpandedChange={(newExpanded, index) => {
                    //   // console.log(newExpanded);
                    //   // console.log(newExpanded[`${index}`]);
                    //   if (newExpanded[`${index}`]) {
                    //     const releaseId = this.state.releases[index].id;
                    //     if (this.state.discogsReleases.hasOwnProperty(releaseId) === false) {
                    //       axios.get(`https://api.discogs.com/releases/${releaseId}?gbp`)
                    //       .then((res) => {
                    //         const releases = this.state.discogsReleases;
                    //         releases[releaseId] = res.data;
                    //         // this.setState({ discogsReleases: releases });
                    //       });
                    //     }
                    //   }
                    //   // console.log(this.state.releases[index].country);
                    // }}
                    SubComponent={row =>
                      (
                        <div style={{ padding: '10px' }}>
                          <ReleaseSummary id={row.original.id} />
                        </div>)}
                  />
                </div>
      )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
});

// export default connect()(SidebarContent);
export default connect(mapStateToProps)(AddNewItemEditor);
