import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import ToolsUpdateOrdersPage from './components/ToolsUpdateOrdersPage';


const ToolsUpdateOrders = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h5 className="page-title">Tools / Orders / Update</h5>
      </Col>
    </Row>
    <Row>
      <ToolsUpdateOrdersPage />
    </Row>
  </Container>
);

export default ToolsUpdateOrders;
