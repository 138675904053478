/* eslint-disable react/prop-types */
/* eslint-disable max-len */

import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import axios from 'axios';

const currencyFormatGbp = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' });

class ReleaseSummary extends React.Component {
  constructor() {
    super();
    this.state = {
      release: null,
    };
  }
  componentWillMount() {
    axios.get(`https://api.discogs.com/releases/${this.props.id}?gbp`)
      .then((res) => {
        this.setState({ release: res.data });
      });
  }
  render() {
    const {
      release,
    } = this.state;

    return (
      <div>
        {release ? (
          <Col md={12} lg={7} xl={9}>
            <Card>
              <CardBody>
                <div className="project-summary">
                  <table className="project-summary__info">
                    <tbody>
                      <tr>
                        <th>Project owner:</th>
                        <td>{release.country}</td>
                      </tr>
                      <tr>
                        <th>Due date:</th>
                        <td>21/12/2018</td>
                      </tr>
                      <tr>
                        <th>Client Brief:</th>
                        <td><a href="/">Design Brief_Global Co.txt</a></td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="project-summary__stats">
                    <div className="project-summary__stat">
                      <p>{release.num_for_sale} <span>Num. For Sale</span></p>
                    </div>
                    <div className="project-summary__stat">
                      <p>{currencyFormatGbp.format(release.lowest_price)} <span>Lowest Price</span></p>
                    </div>
                    <div className="project-summary__stat">
                      <p>{release.community.have} <span>Have</span></p>
                    </div>
                    <div className="project-summary__stat">
                      <p>{release.community.want} <span>Want</span></p>
                    </div>
                  </div>
                  <hr />
                </div>
              </CardBody>
            </Card>
          </Col>
        ) : (
          <span>Loading...</span>
        )}
      </div>
    );
  }
}

export default ReleaseSummary;
