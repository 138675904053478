import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import ShippingToolsEbayPage from './components/ShippingToolsEbayPage';

const ShippingToolsEbay = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h5 className="page-title">Tools / Shipping / eBay</h5>
      </Col>
    </Row>
    <Row>
      <ShippingToolsEbayPage />
    </Row>
  </Container>
);

export default ShippingToolsEbay;
