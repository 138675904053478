import React from 'react';
import { Card, CardBody, Col, Button } from 'reactstrap';

const ExampleCard = () => (
  <Col md={12}>
    <Card>
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">Enter ABD id number</h5>
        </div>
        <input
          type="number"
          name="id"
        />
        <Button
          color="primary"
          size="sm"
          onClick={() => this.saveItem()}
        >Save Changes
        </Button>
      </CardBody>
    </Card>
  </Col>
);

export default ExampleCard;
