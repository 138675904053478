/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable react/style-prop-object */
/* eslint-disable camelcase */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */


import React, { Component } from 'react';
import { Card, CardDeck, CardBody, Col, Button, Row, ButtonToolbar, ButtonGroup, Field } from 'reactstrap';
import ReactTable from 'react-table';
import Select from 'react-select';
import 'react-table/react-table.css';
import axios from 'axios';
import { connect } from 'react-redux';
import { countryList, iso2codes } from '../../../../helpers/shipping/Countries';
import { iso3codes } from '../../../../helpers/shipping/iso3toiso2';
import { hsCodes, marketplaces } from '../../../../helpers/shipping/HSCodes';
import { domesticServices, internationalServices, packageSizes } from '../../../../helpers/shipping/Services';


const currencyFormat = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' });
const URL = 'http://136.144.230.112:8000';

// const currencyFormat = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' })

const renderField = ({
  input, label, placeholder, type, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <input {...input} placeholder={placeholder} type={type} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

class ShipmentsTable extends Component {
  constructor() {
    super();
    this.state = {
      ebayOrders: [],
      amazonOrders: [],
      selectedOrder: {},
      shipmentsBatch: [],
      selectedBatchItem: {},
      manualAddressText: '',
      currentShipment: {},
      currentShipmentCustoms: {
        customs_value: 0,
        marketplace: undefined,
        items: [{
          label: 'Vinyl Record',
          value: 8523809000,
          origin: 'GBR',
          qty: 1,
        },
        ],
      },
      ordersSource: 'amazon',
      // loading: false,
      pages: undefined,
    };
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.getAvailableServices = this.getAvailableServices.bind(this);
    this.getInitalOrders();
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  getInitalOrders() {
    axios.post(`${URL}/admin/orders/ebay`, {
      page: 1,
      pageSize: 150,
      sorted: [],
      filtered: [],
    }, { headers: { Authorization: localStorage.getItem('mm_admin') } })
      .then((res) => {
        this.setState({
          ebayOrders: res.data.orders,
        });
      });

    axios.post(`${URL}/admin/orders/amazon`, {
      page: 1,
      pageSize: 150,
      sorted: [],
      filtered: [],
    }, { headers: { Authorization: localStorage.getItem('mm_admin') } })
      .then((res) => {
      // Update react-table
        this.setState({
          amazonOrders: res.data.orders,
        });
      });
  }

  getAvailableServices(country, size, weight) {
    if (country == null || weight == null || size == null) {
      return [];
    }
    let servicesArray = [];
    if (country.label === 'United Kingdom') {
      if (weight > 1999) {
        servicesArray = domesticServices.filter(service => (service.sizes.includes(size.value) && '20000' in service.prices));
      } else {
        servicesArray = domesticServices.filter(service => service.sizes.includes(size.value));
      }
    } else {
      servicesArray = internationalServices.filter(service => (service.sizes.includes(size.value) && country.services.includes(service.value)));
    }
    return servicesArray;
  }

  clearForm = () => {
    document.getElementById('shipment-form').reset();
    this.setState({ currentShipment: {} });
  }

  handleFieldChange(e, action) {
    const currentShipment = { ...this.state.currentShipment };
    const currentShipmentCustoms = { ...this.state.currentShipmentCustoms };
    if (action) {
      if (action.name === 'shipping_address_country') {
        currentShipment.shipping_address_country = e;
        currentShipment.availableServices = this.getAvailableServices(currentShipment.shipping_address_country, currentShipment.package_size, currentShipment.shipping_package_weight);
        this.setState({ currentShipment });
      }
      if (action.name === 'package_size') {
        currentShipment.package_size = e;
        currentShipment.shipping_service = null;
        currentShipment.availableServices = this.getAvailableServices(currentShipment.shipping_address_country, currentShipment.package_size, currentShipment.shipping_package_weight);
        this.setState({ currentShipment });
      }
      if (action.name === 'shipping_service') {
        currentShipment.shipping_service = e;
        this.setState({ currentShipment });
      }
      if (action.name === 'customs_marketplace') {
        currentShipmentCustoms.marketplace = e;
        this.setState({ currentShipmentCustoms });
        console.log(currentShipmentCustoms.marketplace);
      }
      if (action.name === 'shipping_customs_items') {
        switch (action.action) {
          case 'select-option':
            currentShipmentCustoms.items.push(action.option);
            this.setState({ currentShipmentCustoms });
            return;
          case 'remove-value':
            currentShipmentCustoms.items.splice(currentShipmentCustoms.items.findIndex(el => el.label === action.removedValue.label), 1);
            this.setState({ currentShipmentCustoms });
            return;
          case 'clear':
            currentShipmentCustoms.items = [];
            this.setState({ currentShipmentCustoms });
            break;
          default:
        }
      }
    } else if (e.target.name.includes('customs_item_')) {
      currentShipmentCustoms.items[e.target.name.split('_')[2]].qty = e.target.value;
      this.setState({ currentShipmentCustoms });
    } else if (e.target.name === 'customs_value') {
      currentShipmentCustoms.customs_value = e.target.value;
      this.setState({ currentShipmentCustoms });
    } else if (e.target.name === 'manualAddressText') {
      this.setState({ manualAddressText: e.target.value });
    } else {
      currentShipment[e.target.name] = e.target.value;
      currentShipment.availableServices = this.getAvailableServices(currentShipment.shipping_address_country, currentShipment.package_size, currentShipment.shipping_package_weight);
      this.setState({ currentShipment });
    }
  }

  parseManualAddress() {
    const { manualAddressText } = this.state;
    this.clearForm();
    const currentShipment = { };
    const currentShipmentCustoms = {
      customs_value: 0,
      marketplace: null,
      items: [{
        label: 'Vinyl Record',
        value: 8523809000,
        origin: 'GBR',
        qty: 1,
      },
      ],
    };

    const addressSplit = manualAddressText.split(/[\n,]+/);
    const nameSplit = addressSplit[0].split(' ');
    let countryLabel;
    currentShipment.order_source = this.state.ordersSource;
    currentShipment.shipping_service = null;
    currentShipment.package_size = null;
    currentShipment.buyer_email = null;
    currentShipment.buyer_phone = null;
    currentShipment.shipping_address_firstname = nameSplit[0];
    currentShipment.shipping_address_lastname = nameSplit.slice(1).join(' ');
    addressSplit.shift();
    switch (addressSplit.length) {
      case 3:
        currentShipment.shipping_address1 = addressSplit[0].trim();
        currentShipment.shipping_address_city = addressSplit[1].trim();
        countryLabel = addressSplit[2].trim();
        break;
      case 4:
        currentShipment.shipping_address1 = addressSplit[0].trim();
        currentShipment.shipping_address_city = addressSplit[1].trim();
        currentShipment.shipping_address_postcode = addressSplit[2].trim();
        countryLabel = addressSplit[3].trim();
        break;
      case 5:
        currentShipment.shipping_address1 = addressSplit[0].trim();
        currentShipment.shipping_address_city = addressSplit[1].trim();
        currentShipment.shipping_address_region = addressSplit[2].trim();
        currentShipment.shipping_address_postcode = addressSplit[3].trim();
        countryLabel = addressSplit[4].trim();
        break;
      case 6:
        currentShipment.shipping_address1 = addressSplit[0].trim();
        currentShipment.shipping_address2 = addressSplit[1].trim();
        currentShipment.shipping_address_city = addressSplit[2].trim();
        currentShipment.shipping_address_region = addressSplit[3].trim();
        currentShipment.shipping_address_postcode = addressSplit[4].trim();
        countryLabel = addressSplit[5].trim();
        break;
      case 7:
        currentShipment.shipping_address1 = addressSplit[0].trim();
        currentShipment.shipping_address2 = addressSplit[1].trim();
        currentShipment.shipping_address3 = addressSplit[2].trim();
        currentShipment.shipping_address_city = addressSplit[3].trim();
        currentShipment.shipping_address_region = addressSplit[4].trim();
        currentShipment.shipping_address_postcode = addressSplit[5].trim();
        countryLabel = addressSplit[6].trim();
        break;
      case 8:
        currentShipment.shipping_address1 = addressSplit[0].trim();
        currentShipment.shipping_address2 = `${addressSplit[1].trim()}, ${addressSplit[2].trim()}`;
        currentShipment.shipping_address3 = addressSplit[3].trim();
        currentShipment.shipping_address_city = addressSplit[4].trim();
        currentShipment.shipping_address_region = addressSplit[5].trim();
        currentShipment.shipping_address_postcode = addressSplit[6].trim();
        countryLabel = addressSplit[7].trim();
        break;
      default:
    }

    if (countryLabel !== undefined) {
      const countryIndex = countryList.map(e => e.label.toLowerCase()).indexOf(countryLabel.toLowerCase());
      currentShipment.shipping_address_country = countryList[countryIndex];
      currentShipment.shipping_address_countrycode = iso3codes[countryList[countryIndex].value];
    }

    this.setState({
      currentShipment,
      currentShipmentCustoms,
    });
  }

  submitShipmentBatch() {
    // console.log('SUBMITTING BATCH');
    // console.log(this.state.shipmentsBatch);
    // const batchItemIds = [];
    const shipmentsBatch = this.state.shipmentsBatch;
    shipmentsBatch.map((item, index) => {
      item.batchPosition = index;
    });
    axios.post(`${URL}/admin/shipping/shipments/create`, {
      shipments: this.state.shipmentsBatch,
    }, {
      headers: {
        Authorization: localStorage.getItem('mm_admin'),
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
      },
      responseType: 'arraybuffer',
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.pdf'); // or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(error);
      });
  }


  render() {
    const ordersColumns = [
      {
        Header: 'Order Id',
        id: `${this.state.ordersSource}_order_id`,
        accessor: `${this.state.ordersSource}_order_id`,
      }, {
        Header: 'Name',
        id: 'buyer_name',
        accessor: 'buyer_name',
      }, {
        Header: 'Service',
        id: 'shipping_service_level',
        accessor: 'shipping_service_level',
      },
    ];

    const batchColumns = [
      {
        Header: 'First Name',
        id: 'shipment.shipping_address_firstname',
        accessor: 'shipment.shipping_address_firstname',
      }, {
        Header: 'Last Name',
        id: 'shipment.shipping_address_lastname',
        accessor: 'shipment.shipping_address_lastname',
      }, {
        Header: 'Country',
        id: 'shipment.shipping_address_country.label',
        accessor: 'shipment.shipping_address_country.label',
      },
      {
        Header: 'Size',
        id: 'shipment.package_size.label',
        accessor: 'shipment.package_size.label',
      },
      {
        Header: 'Service',
        id: 'shipment.shipping_service.label',
        accessor: 'shipment.shipping_service.label',
      },
    ];

    return (
      <Row>
        <Col md={4}>
          <Card>
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">Address Details:</h5>
              </div>
              <form id="shipment-form" className="form form--horizontal">
                <div className="form__form-group">
                  <span className="form__form-group-label">First Name</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap">
                      <input
                        type="text"
                        name="shipping_address_firstname"
                        value={this.state.currentShipment.shipping_address_firstname}
                        onChange={this.handleFieldChange}
                      />
                      { !this.state.currentShipment.shipping_address_firstname && <span className="form__form-group-error">Required</span> }
                    </div>
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Last Name</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap">
                      <input
                        type="text"
                        name="shipping_address_lastname"
                        value={this.state.currentShipment.shipping_address_lastname}
                        onChange={this.handleFieldChange}
                      />
                      { !this.state.currentShipment.shipping_address_lastname && <span className="form__form-group-error">Required</span> }
                    </div>
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Address Line 1</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap">
                      <input
                        type="text"
                        name="shipping_address1"
                        value={this.state.currentShipment.shipping_address1}
                        onChange={this.handleFieldChange}
                      />
                      { !this.state.currentShipment.shipping_address1 && <span className="form__form-group-error">Required</span> }
                    </div>
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Address Line 2</span>
                  <div className="form__form-group-field">
                    <input
                      type="text"
                      name="shipping_address2"
                      value={this.state.currentShipment.shipping_address2}
                      onChange={this.handleFieldChange}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Address Line 3</span>
                  <div className="form__form-group-field">
                    <input
                      disabled={!this.state.currentShipment.shipping_address2}
                      type="text"
                      name="shipping_address3"
                      value={this.state.currentShipment.shipping_address3}
                      onChange={this.handleFieldChange}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">City</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap">
                      <input
                        type="text"
                        name="shipping_address_city"
                        value={this.state.currentShipment.shipping_address_city}
                        onChange={this.handleFieldChange}
                      />
                      { !this.state.currentShipment.shipping_address_city && <span className="form__form-group-error">Required</span> }
                    </div>
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">County</span>
                  <div className="form__form-group-field">
                    <input
                      type="text"
                      name="shipping_address_county"
                      value={this.state.currentShipment.shipping_address_region}
                      onChange={this.handleFieldChange}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Postcode</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap">
                      <input
                        type="text"
                        name="shipping_address_postcode"
                        value={this.state.currentShipment.shipping_address_postcode ? this.state.currentShipment.shipping_address_postcode.toUpperCase() : null}
                        onChange={this.handleFieldChange}
                      />
                      { !this.state.currentShipment.shipping_address_postcode && <span className="form__form-group-error">Required</span> }
                    </div>
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Country</span>
                  <div className="form__form-group-field">
                    <div style={{ width: '100%' }}>
                      <Select
                        name="shipping_address_country"
                        options={countryList}
                        value={this.state.currentShipment.shipping_address_country}
                        onChange={this.handleFieldChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Phone</span>
                  <div className="form__form-group-field">
                    <input
                      type="text"
                      name="buyer_phone"
                      value={this.state.currentShipment.buyer_phone}
                      onChange={this.handleFieldChange}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Email</span>
                  <div className="form__form-group-field">
                    <input
                      type="text"
                      name="buyer_email"
                      value={this.state.currentShipment.buyer_email}
                      onChange={this.handleFieldChange}
                    />
                  </div>
                </div>
                <div className="card__title">
                  <h5 className="bold-text">Shipment Details:</h5>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Order Ref</span>
                  <div className="form__form-group-field">
                    <input
                      type="text"
                      name="order_ref"
                      value={this.state.currentShipment.order_ref}
                      onChange={this.handleFieldChange}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Weight</span>
                  <div className="form__form-group-field">
                    <input
                      type="number"
                      name="shipping_package_weight"
                      value={this.state.currentShipment.shipping_package_weight || null}
                      onChange={this.handleFieldChange}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Size</span>
                  <div className="form__form-group-field">
                    <div style={{ width: '100%' }}>
                      <Select
                        name="package_size"
                        isDisabled={!this.state.currentShipment.shipping_package_weight}
                        options={packageSizes}
                        value={this.state.currentShipment.package_size}
                        onChange={this.handleFieldChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Service</span>
                  <div className="form__form-group-field">
                    <div style={{ width: '100%' }}>
                      <Select
                        name="shipping_service"
                        isDisabled={!this.state.currentShipment.package_size}
                        options={this.state.currentShipment.availableServices}
                        value={this.state.currentShipment.shipping_service}
                        onChange={this.handleFieldChange}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ width: '100%' }}>
                  {this.state.currentShipment.shipping_address_country != null && this.state.currentShipment.shipping_address_country.label !== 'United Kingdom' ? (
                    <div style={{ width: '100%' }}>
                      <div className="card__title">
                        <h5 className="bold-text">Customs Details:</h5>
                      </div>
                      <div className="form__form-group">
                        <span className="form__form-group-label">Marketplace</span>
                        <div className="form__form-group-field">
                          <div style={{ width: '100%' }}>
                            <Select
                              name="customs_marketplace"
                              isDisabled={this.state.ordersSource !== 'manual'}
                              options={marketplaces}
                              value={this.state.currentShipmentCustoms.marketplace}
                              onChange={this.handleFieldChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form__form-group">
                        <span className="form__form-group-label">Items</span>
                        <div className="form__form-group-field">
                          <div style={{ width: '100%' }}>
                            <Select
                              name="shipping_customs_items"
                              options={hsCodes}
                              defaultValue={this.state.currentShipmentCustoms.items}
                              onChange={this.handleFieldChange}
                              isMulti
                            />
                          </div>
                        </div>
                      </div>
                      {this.state.currentShipmentCustoms.items.map((customsItem, index) => (
                        <div className="form__form-group">
                          <span className="form__form-group-label">{customsItem.label} Qty:</span>
                          <div className="form__form-group-field">
                            <input
                              type="number"
                              name={`customs_item_${index}`}
                              value={customsItem.qty}
                              onChange={this.handleFieldChange}
                            />
                          </div>
                        </div>
                      ))}
                      <div className="form__form-group">
                        <span className="form__form-group-label">Total Value</span>
                        <div className="form__form-group-field">
                          <input
                            type="number"
                            name="customs_value"
                            value={this.state.currentShipmentCustoms.customs_value}
                            onChange={this.handleFieldChange}
                          />
                        </div>
                      </div>
                    </div>
              ) : null }
                </div>
              </form>
              { this.state.shipmentsBatch.indexOf(this.state.selectedBatchItem) > -1 ? (
                <center>
                  <div>
                    <Button
                      color="warning"
                      onClick={() => {
                        const { shipmentsBatch } = this.state;
                        shipmentsBatch[this.state.shipmentsBatch.indexOf(this.state.selectedBatchItem)] = { shipment: this.state.currentShipment, customs: this.state.currentShipmentCustoms };
                        this.setState({ shipmentsBatch });
                        this.clearForm();
                        }
                      }
                    >Update Batch Item
                    </Button>
                    <Button
                      color="danger"
                      onClick={() => {
                        const { shipmentsBatch } = this.state;
                        shipmentsBatch.splice(this.state.shipmentsBatch.indexOf(this.state.selectedBatchItem), 1);
                        this.setState({ shipmentsBatch });
                        this.clearForm();
                        }
                      }
                    >Delete Batch Item
                    </Button>
                  </div>
                </center>
              ) : (
                <center>
                  <Button
                    color="primary"
                    disabled={!this.state.currentShipment.shipping_service || !this.state.currentShipment.package_size || !this.state.currentShipment.shipping_package_weight}
                    onClick={() => {
                      this.setState({ shipmentsBatch: [...this.state.shipmentsBatch, { shipment: this.state.currentShipment, customs: this.state.currentShipmentCustoms }] });
                      this.clearForm();
                      }
                    }
                  >Add to Batch
                  </Button>
                </center>
              )}
            </CardBody>
          </Card>
        </Col>
        <Col md={8}>
          <Card>
            <CardBody>
              <ButtonToolbar>
                <ButtonGroup className="btn-group--justified" dir="ltr">
                  <Button outline={this.state.ordersSource !== 'amazon'} color="primary" onClick={() => this.setState({ ordersSource: 'amazon' })}>Amazon</Button>
                  <Button outline={this.state.ordersSource !== 'ebay'} color="primary" onClick={() => this.setState({ ordersSource: 'ebay' })}>eBay</Button>
                  <Button outline={this.state.ordersSource !== 'manual'} color="primary" onClick={() => this.setState({ ordersSource: 'manual' })}>Manual</Button>
                </ButtonGroup>
              </ButtonToolbar>
              {this.state.ordersSource === 'manual' ? (
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <textarea id="manualAddressText" name="manualAddressText" value={this.state.manualAddressText} onChange={this.handleFieldChange} rows="10" style={{ width: '50%', float: 'right' }} />
                    <div>
                      <Button color="primary" onClick={() => this.parseManualAddress()}>Parse Address</Button>
                    </div>
                  </div>
                </div>
              ) :
                <ReactTable
                  pages={this.state.pages}
                  data={this.state.ordersSource === 'ebay' ? this.state.ebayOrders : this.state.amazonOrders}
                  columns={ordersColumns}
                  showPagination={false}
                  className="-striped -highlight -responsive"
                  filterable
                  loading={this.state.ordersSource === 'ebay' && this.state.ebayOrders.length === 0 ? true : !!(this.state.ordersSource === 'amazon' && this.state.amazonOrders.length === 0)}
                  style={{
                    height: '400px',
                  }}
                  defaultPageSize={20}
                  getTrProps={(state, rowInfo) => {
                    if (rowInfo !== undefined) {
                      return {
                      onClick: (e) => {
                      this.clearForm();
                      const currentShipment = Object.assign({}, rowInfo.original);
                      const { currentShipmentCustoms } = this.state;
                      currentShipment.shipping_address_firstname = rowInfo.original.buyer_name ? rowInfo.original.buyer_name.split(' ')[0] : null;
                      currentShipment.shipping_address_lastname = rowInfo.original.buyer_name ? rowInfo.original.buyer_name.split(' ').splice(1).join(' ') : null;
                      currentShipment.shipping_address_country = countryList.find(x => x.value === iso2codes[currentShipment.shipping_address_countrycode]);
                      currentShipment.shipping_service = null;
                      currentShipment.package_size = null;
                      currentShipment.order_ref = rowInfo.original.ebay_order_id || rowInfo.original.amazon_order_id || null;
                      currentShipment.order_source = this.state.ordersSource;
                      currentShipmentCustoms.customs_value = rowInfo.original.order_total;
                      currentShipmentCustoms.marketplace = marketplaces.find(x => x.value === this.state.ordersSource);
                      this.setState({
                      selectedOrder: rowInfo.original,
                      selectedBatchItem: null,
                      currentShipment,
                      currentShipmentCustoms,
                      });
                    },
                    style: {
                        background: rowInfo.original === this.state.selectedOrder ? '#00afec' : 'white',
                        color: rowInfo.original === this.state.selectedOrder ? 'white' : 'black',
                      },
                    };
                  }
                  return {};
                  }}
                />}
              <div className="card__title">
                <h5 className="bold-text">Current Batch</h5>
              </div>
              <ReactTable
                data={this.state.shipmentsBatch}
                columns={batchColumns}
                showPagination={false}
                className="-striped -highlight -responsive"
                style={{
                  height: '400px',
                }}
                defaultPageSize={100}
                getTrProps={(state, rowInfo) => {
                  if (rowInfo !== undefined) {
                    return {
                    onClick: (e) => {
                      console.log('CURRENT ROW');
                      console.log(rowInfo.original);
                      this.setState({
                      selectedBatchItem: rowInfo.original,
                      currentShipment: rowInfo.original.shipment,
                      currentShipmentCustoms: rowInfo.original.customs || null,
                      selectedOrder: null,
                      });
                  },
                  style: {
                      background: rowInfo.original === this.state.selectedBatchItem ? '#00afec' : 'white',
                      color: rowInfo.original === this.state.selectedBatchItem ? 'white' : 'black',
                    },
                  };
                }
                return {};
                }}
              />
              <br />
              <Button
                color="primary"
                onClick={() => this.submitShipmentBatch()}
              >Submit Batch
              </Button>
              <Button
                color="danger"
                onClick={() => {
                  let { shipmentsBatch } = this.state;
                  shipmentsBatch = [];
                  this.setState({ shipmentsBatch });
                  }
                }
              >Clear All
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
});

// export default connect()(SidebarContent);
export default connect(mapStateToProps)(ShipmentsTable);
