import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import AllOrdersTable from './components/AllOrdersTable';


const AllOrders = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h5 className="page-title">Fulfillment / Completed Orders</h5>
      </Col>
    </Row>
    <Row>
      <AllOrdersTable />
    </Row>
  </Container>
);

export default AllOrders;
