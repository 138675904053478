export const domesticServices = [
  {
    value: 'CRL24S',
    label: 'Royal Mail Signed For® 1st Class',
    compensation: 50,
    obaPricing: {
      1000: 3.93,
      1250: 4.11,
      1500: 4.28,
      1750: 4.43,
      2000: 4.69,
    },
    sizes: ['SP'],
    prices: {
      1000: 4.48,
      2000: 6.45,
    },
  },
  {
    value: 'CRL48',
    label: 'Royal Mail 2nd Class',
    compensation: 20,
    obaPricing: {
      100: 0.73,
      250: 1.03,
      500: 1.15,
      750: 1.53,
    },
    sizes: ['LL'],
    prices: {
      100: 0.73,
      250: 1.03,
      500: 1.15,
      750: 1.53,
    },
  },
  {
    value: 'CRL48',
    label: 'Royal Mail 2nd Class',
    compensation: 20,
    obaPricing: {
      1000: 2.32,
      1250: 2.61,
      1500: 2.61,
      1750: 2.61,
      2000: 2.61,
    },
    sizes: ['SP'],
    prices: {
      1000: 2.95,
      2000: 2.95,
    },
  },
  {
    value: 'TPS48',
    label: 'Royal Mail Tracked 48',
    compensation: 100,
    obaPricing: {
      1000: 3.05,
      1250: 3.05,
      1500: 3.05,
      1750: 3.05,
      2000: 3.05,
      20000: 3.05,
    },
    sizes: ['SP'],
    prices: {
      1000: 4.10,
      2000: 4.10,
      20000: 8.00,
    },
  },
  {
    value: 'CRL48S',
    label: 'Royal Mail Signed For® 2nd Class',
    compensation: 50,
    obaPricing: {
      100: 1.83,
      250: 2.13,
      500: 2.25,
      750: 2.63,
    },
    sizes: ['LL'],
    prices: {
      100: 1.96,
      250: 2.26,
      500: 2.38,
      750: 2.76,
    },
  },
  {
    value: 'CRL48S',
    label: 'Royal Mail Signed For® 2nd Class',
    compensation: 50,
    obaPricing: {
      1000: 3.45,
      1250: 3.74,
      1500: 3.74,
      1750: 3.74,
      2000: 3.74,
    },
    sizes: ['SP'],
    prices: {
      1000: 3.87,
      2000: 3.87,
    },
  },
];

export const internationalServices = [
  {
    value: 'MP1',
    label: 'Royal Mail International Tracked',
    sizes: ['SP'],
    compensation: 100,
    obaPricing: {
      EU: {
        PPI: 5.65,
        PPK: 3.65,
      },
      ROW: {
        PPI: 6.15,
        PPK: 5.80,
      },
    },
    prices: {
      500: {
        EU: 9.88,
        1: 11.90,
        2: 12.48,
      },
      750: {
        EU: 10.91,
        1: 13.96,
        2: 14.58,
      },
      1000: {
        EU: 11.65,
        1: 15.65,
        2: 16.27,
      },
      1250: {
        EU: 12.23,
        1: 16.39,
        2: 17.18,
      },
      1500: {
        EU: 13.10,
        1: 17.54,
        2: 18.57,
      },
      1750: {
        EU: 13.67,
        1: 18.52,
        2: 19.69,
      },
      2000: {
        EU: 13.92,
        1: 19.03,
        2: 20.47,
      },
    },
  }, {
    value: 'MTI',
    label: 'Royal Mail International Tracked',
    sizes: ['LL'],
    compensation: 100,
    obaPricing: {
      EU: {
        PPI: 5.45,
        PPK: 3.85,
      },
      ROW: {
        PPI: 5.90,
        PPK: 5.90,
      },
    },
    prices: {
      100: {
        EU: 6.77,
        1: 7.33,
        2: 7.45,
      },
      250: {
        EU: 7.69,
        1: 8.56,
        2: 8.81,
      },
      500: {
        EU: 8.13,
        1: 10.01,
        2: 10.33,
      },
      750: {
        EU: 8.81,
        1: 11.53,
        2: 11.98,
      },
    },
  }, {
    value: 'MP5',
    label: 'Royal Mail International Signed',
    sizes: ['SP'],
    compensation: 100,
    obaPricing: {
      EU: {
        PPI: 6.05,
        PPK: 3.65,
      },
      ROW: {
        PPI: 6.05,
        PPK: 5.80,
      },
    },
    prices: {
      500: {
        EU: 9.88,
        1: 11.90,
        2: 12.48,
      },
      750: {
        EU: 10.91,
        1: 13.96,
        2: 14.58,
      },
      1000: {
        EU: 11.65,
        1: 15.65,
        2: 16.27,
      },
      1250: {
        EU: 12.23,
        1: 16.39,
        2: 17.18,
      },
      1500: {
        EU: 13.10,
        1: 17.54,
        2: 18.57,
      },
      1750: {
        EU: 13.67,
        1: 18.52,
        2: 19.69,
      },
      2000: {
        EU: 13.92,
        1: 19.03,
        2: 20.47,
      },
    },
  }, {
    value: 'MTM',
    label: 'Royal Mail International Signed',
    sizes: ['LL'],
    compensation: 100,
    obaPricing: {
      EU: {
        PPI: 5.80,
        PPK: 3.85,
      },
      ROW: {
        PPI: 5.80,
        PPK: 5.90,
      },
    },
    prices: {
      100: {
        EU: 6.77,
        1: 7.33,
        2: 7.45,
      },
      250: {
        EU: 7.69,
        1: 8.56,
        2: 8.81,
      },
      500: {
        EU: 8.13,
        1: 10.01,
        2: 10.33,
      },
      750: {
        EU: 8.81,
        1: 11.53,
        2: 11.98,
      },
    },
  },
  {
    value: 'MTA',
    label: 'Royal Mail International Tracked & Signed',
    sizes: ['SP'],
    compensation: 100,
    obaPricing: {
      EU: {
        PPI: 6.65,
        PPK: 3.65,
      },
      ROW: {
        PPI: 6.65,
        PPK: 5.80,
      },
    },
    prices: {
      500: {
        EU: 9.88,
        1: 11.90,
        2: 12.48,
      },
      750: {
        EU: 10.91,
        1: 13.96,
        2: 14.58,
      },
      1000: {
        EU: 11.65,
        1: 15.65,
        2: 16.27,
      },
      1250: {
        EU: 12.23,
        1: 16.39,
        2: 17.18,
      },
      1500: {
        EU: 13.10,
        1: 17.54,
        2: 18.57,
      },
      1750: {
        EU: 13.67,
        1: 18.52,
        2: 19.69,
      },
      2000: {
        EU: 13.92,
        1: 19.03,
        2: 20.47,
      },
    },
  }, {
    value: 'MTC',
    label: 'Royal Mail International Tracked & Signed',
    sizes: ['LL'],
    compensation: 100,
    obaPricing: {
      EU: {
        PPI: 6.20,
        PPK: 3.85,
      },
      ROW: {
        PPI: 6.20,
        PPK: 5.90,
      },
    },
    prices: {
      100: {
        EU: 6.77,
        1: 7.33,
        2: 7.45,
      },
      250: {
        EU: 7.69,
        1: 8.56,
        2: 8.81,
      },
      500: {
        EU: 8.13,
        1: 10.01,
        2: 10.33,
      },
      750: {
        EU: 8.81,
        1: 11.53,
        2: 11.98,
      },
    },
  },
  {
    value: 'IE1',
    label: 'Royal Mail International Standard',
    sizes: ['SP'],
    compensation: 20,
    obaPricing: {
      EU: {
        PPI: 1.635,
        PPK: 4.250,
      },
      ROW: {
        PPI: 1.735,
        PPK: 6.755,
      },
      USA: {
        PPI: 3.500,
        PPK: 12.060,
      },
    },
    prices: {
      500: {
        EU: 5.54,
        1: 7.78,
        2: 8.40,
        3: 12.17,
      },
      750: {
        EU: 6.47,
        1: 9.50,
        2: 10.12,
        3: 16.02,
      },
      1000: {
        EU: 7.24,
        1: 11.22,
        2: 11.85,
        3: 19.88,
      },
      1250: {
        EU: 7.84,
        1: 12.60,
        2: 13.44,
        3: 23.74,
      },
      1500: {
        EU: 8.57,
        1: 13.83,
        2: 13.93,
        3: 25.27,
      },
      1750: {
        EU: 9.06,
        1: 14.07,
        2: 15.33,
        3: 27.50,
      },
      2000: {
        EU: 9.31,
        1: 14.66,
        2: 16.13,
        3: 29.71,
      },
    },
  },
  {
    value: 'IG1',
    label: 'Royal Mail International Standard',
    sizes: ['LL'],
    compensation: 20,
    obaPricing: {
      EU: {
        PPI: 0.915,
        PPK: 4.315,
      },
      ROW: {
        PPI: 0.935,
        PPK: 6.510,
      },
      USA: {
        PPI: 0.935,
        PPK: 6.510,
      },
    },
    prices: {
      100: {
        EU: 2.12,
        1: 2.68,
        2: 2.80,
        3: 2.68,
      },
      250: {
        EU: 3.04,
        1: 3.91,
        2: 4.16,
        3: 3.91,
      },
      500: {
        EU: 3.48,
        1: 5.36,
        2: 5.69,
        3: 5.36,
      },
      750: {
        EU: 4.16,
        1: 6.88,
        2: 7.33,
        3: 6.88,
      },
    },
  },
  {
    value: 'GPT',
    label: 'DHL Tracked',
    sizes: ['SP'],
    compensation: 30,
    obaPricing: {
      0: {
        PPI: 2.65,
        PPK: 3.00,
      },
      1: {
        PPI: 3.35,
        PPK: 4.00,
      },
      2: {
        PPI: 3.00,
        PPK: 6.00,
      },
      3: {
        PPI: 4.00,
        PPK: 3.80,
      },
      4: {
        PPI: 5.00,
        PPK: 3.10,
      },
      // 5: {
      //   PPI: 5.00,
      //   PPK: 8.00,
      // },
      6: {
        PPI: 4.65,
        PPK: 11.00,
      },
      7: {
        PPI: 3.95,
        PPK: 7.50,
      },
      8: {
        PPI: 4.60,
        PPK: 10.50,
      },
      9: {
        PPI: 1.80,
        PPK: 13.50,
      },
    },
    prices: {
      100: {
        0: 10.89,
        1: 10.89,
        2: 10.89,
        3: 10.89,
        4: 10.89,
        // 5: 4.20, UK
        6: 18.77, // USA
        7: 14.94,
        8: 16.92,
        9: 16.92,
      },
      250: {
        0: 10.89,
        1: 10.89,
        2: 10.89,
        3: 10.89,
        4: 10.89,
        // 5: 4.20, UK
        6: 18.77, // USA
        7: 14.94,
        8: 16.92,
        9: 16.92,
      },
      500: {
        0: 10.89,
        1: 10.89,
        2: 10.89,
        3: 10.89,
        4: 10.89,
        // 5: 4.20, UK
        6: 18.77, // USA
        7: 14.94,
        8: 16.92,
        9: 16.92,
      },
      750: {
        0: 11.84,
        1: 11.84,
        2: 11.84,
        3: 11.84,
        4: 11.84,
        // 5: 4.20, UK
        6: 20.57, // USA
        7: 17.10,
        8: 19.44,
        9: 19.44,
      },
      1000: {
        0: 12.69,
        1: 12.69,
        2: 12.69,
        3: 12.69,
        4: 12.69,
        // 5: 4.20, UK
        6: 23.85, // USA
        7: 19.49,
        8: 22.28,
        9: 22.28,
      },
      1250: {
        0: 13.05,
        1: 13.05,
        2: 13.05,
        3: 13.05,
        4: 13.05,
        // 5: 4.20, UK
        6: 26.96, // USA
        7: 21.15,
        8: 24.44,
        9: 24.44,
      },
      1500: {
        0: 13.55,
        1: 13.55,
        2: 13.55,
        3: 13.55,
        4: 13.55,
        // 5: 4.20, UK
        6: 29.43, // USA
        7: 22.41,
        8: 26.69,
        9: 26.69,
      },
      2000: {
        0: 13.73,
        1: 13.73,
        2: 13.73,
        3: 13.73,
        4: 13.73,
        // 5: 4.20, UK
        6: 29.88, // USA
        7: 22.82,
        8: 27.63,
        9: 27.63,
      },
    },
  },
  {
    value: 'GPP',
    label: 'DHL Signed',
    sizes: ['SP'],
    compensation: 30,
    obaPricing: {
      0: {
        PPI: 4.60,
        PPK: 3.00,
      },
      1: {
        PPI: 5.10,
        PPK: 4.00,
      },
      2: {
        PPI: 4.40,
        PPK: 6.00,
      },
      3: {
        PPI: 5.68,
        PPK: 3.80,
      },
      4: {
        PPI: 6.65,
        PPK: 3.10,
      },
      // 5: {
      //   PPI: 6.00,
      //   PPK: 8.00,
      // },
      6: {
        PPI: 6.55,
        PPK: 11.00,
      },
      7: {
        PPI: 4.40,
        PPK: 7.50,
      },
      8: {
        PPI: 4.65,
        PPK: 10.50,
      },
      9: {
        PPI: 3.60,
        PPK: 13.50,
      },
    },
    prices: {
      100: {
        0: 10.89,
        1: 10.89,
        2: 10.89,
        3: 10.89,
        4: 10.89,
        // 5: 4.20, UK
        6: 18.77, // USA
        7: 14.94,
        8: 16.92,
        9: 16.92,
      },
      250: {
        0: 10.89,
        1: 10.89,
        2: 10.89,
        3: 10.89,
        4: 10.89,
        // 5: 4.20, UK
        6: 18.77, // USA
        7: 14.94,
        8: 16.92,
        9: 16.92,
      },
      500: {
        0: 10.89,
        1: 10.89,
        2: 10.89,
        3: 10.89,
        4: 10.89,
        // 5: 4.20, UK
        6: 18.77, // USA
        7: 14.94,
        8: 16.92,
        9: 16.92,
      },
      750: {
        0: 11.84,
        1: 11.84,
        2: 11.84,
        3: 11.84,
        4: 11.84,
        // 5: 4.20, UK
        6: 20.57, // USA
        7: 17.10,
        8: 19.44,
        9: 19.44,
      },
      1000: {
        0: 12.69,
        1: 12.69,
        2: 12.69,
        3: 12.69,
        4: 12.69,
        // 5: 4.20, UK
        6: 23.85, // USA
        7: 19.49,
        8: 22.28,
        9: 22.28,
      },
      1250: {
        0: 13.05,
        1: 13.05,
        2: 13.05,
        3: 13.05,
        4: 13.05,
        // 5: 4.20, UK
        6: 26.96, // USA
        7: 21.15,
        8: 24.44,
        9: 24.44,
      },
      1500: {
        0: 13.55,
        1: 13.55,
        2: 13.55,
        3: 13.55,
        4: 13.55,
        // 5: 4.20, UK
        6: 29.43, // USA
        7: 22.41,
        8: 26.69,
        9: 26.69,
      },
      2000: {
        0: 13.73,
        1: 13.73,
        2: 13.73,
        3: 13.73,
        4: 13.73,
        // 5: 4.20, UK
        6: 29.88, // USA
        7: 22.82,
        8: 27.63,
        9: 27.63,
      },
    },
  },
];

export const packageSizes = [
  {
    value: 'LL',
    label: 'Large Letter',
    max_weight: 750,
  },
  {
    value: 'SP',
    label: 'Small Parcel',
    max_weight: 20000,
  },
];
